import { createSlice } from "@reduxjs/toolkit";
import { DocumentInfo } from "interfaces/interfaces";

const initialState: {
    documentInfos: DocumentInfo | null;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
} = {
    documentInfos: null,
    status: "idle",
    error: null,
};

export const documentInfosSlice = createSlice({
    name: "documentInfos",
    initialState,
    reducers: {
        fetchDocumentInfosStart(state) {
            state.status = "loading";
        },
        fetchDocumentInfosSuccess(state, action) {
            state.status = "succeeded";
            state.documentInfos = action.payload;
        },
        fetchDocumentInfosFailure(state, action) {
            state.status = "failed";
            state.error = action.payload;
        },
    },
});

export const { fetchDocumentInfosStart, fetchDocumentInfosSuccess, fetchDocumentInfosFailure } =
    documentInfosSlice.actions;

export default documentInfosSlice.reducer;
