/* eslint-disable default-param-last */
import { notify } from "@sobrus-com/sobrus-design-system-v2";
import { DataContext } from "context/DataContext";
import { isEqual } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import API from "API/ApiConfig";
import { catchFunction } from "utils/functionHelper";

export const useFetch = (op, url = "", name = "", initialQuery = {}) => {
    const initialQueryState = useMemo(
        () => ({
            page: 1,
            limit: 20,
            order: "DESC",
            orderBy: "id",
            ...initialQuery,
        }),
        [initialQuery]
    );
    const dataFromLocalstorage = JSON.parse(localStorage.getItem(op));
    const [params, setParams] = useState({
        formData: dataFromLocalstorage?.formData || {},
        queryState: dataFromLocalstorage?.queryState || initialQueryState,
    });

    const { tableData, setTableData } = useContext(DataContext);

    const [data, setData] = useState([]);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const [formLoadingLoading, setFormLoadingLoading] = useState(false);
    const [loading, setLoading] = useState(!(tableData[op]?.isEmty === false && isEqual(params, tableData[op].params)));

    const getData = async (signal, seLoad, callback) => {
        try {
            seLoad(true);
            const res = await API.get(`${url}`, {
                params: { ...params.queryState, ...params.formData },
                signal,
            });
            setData(res?.data[name] || res?.data);
            seLoad(false);
            callback(res);
        } catch (error) {
            catchFunction(error, seLoad);
        }
    };

    const FetchGet = useCallback(
        async (signal) => {
            unstable_batchedUpdates(async () => {
                if (tableData[op]?.isEmty === false && isEqual(params, tableData[op].params)) {
                    tableData[op].noAnimation = true;
                    setData(tableData[op].data);
                    getData(signal, setLoadingNewData, (res) =>
                        setTableData((prev) => ({
                            ...prev,
                            [op]: {
                                ...prev[op],
                                data: res?.data[name] || res?.data,
                            },
                        }))
                    );
                } else {
                    const setLoader = tableData[op]?.noAnimation === true ? setLoadingNewData : setLoading;
                    getData(signal, setLoader, (res) =>
                        setTableData((prev) => ({
                            ...prev,
                            [op]: {
                                data: res?.data[name] || res?.data,
                                isEmty: false,
                                params: {
                                    queryState: params.queryState,
                                    formData: params?.formData,
                                },
                                noAnimation: true,
                            },
                        }))
                    );
                }
            });
            Object.keys(params).length > 0 && localStorage.setItem(op, JSON.stringify(params));
        },
        [params]
    );
    const postData = async (signal, seLoad, formData, callback) => {
        try {
            seLoad(true);
            const res = await API.post(`${url}`, formData, {
                params: params.queryState,
                signal,
            });
            unstable_batchedUpdates(() => {
                setData(res?.data[name] || res?.data);
                seLoad(false);
            });
            callback(res);
        } catch (error) {
            catchFunction(error, seLoad);
        }
    };
    const FetchPost = useCallback(
        async (signal, asyncSearch = null) => {
            unstable_batchedUpdates(async () => {
                if (tableData[op]?.isEmty === false && isEqual(params, tableData[op].params)) {
                    tableData[op].noAnimation = true;
                    setData(tableData[op].data);
                    const tableFormData = tableData[op]?.params?.formData?.client
                        ? {
                              ...tableData[op]?.params?.formData,
                              client: tableData[op]?.formData?.params?.client
                                  ? tableData[op]?.formData?.params?.client
                                  : "",
                          }
                        : tableData[op]?.params?.formData;
                    postData(signal, setLoadingNewData, tableFormData, (res) =>
                        setTableData((prev) => ({
                            ...prev,
                            [op]: {
                                ...prev[op],
                                data: res?.data[name] || res?.data,
                            },
                        }))
                    );
                } else {
                    // // console.log(params.formData, '------');
                    const formData = params.formData?.client
                        ? {
                              ...params.formData,
                              client: params.formData?.client
                                  ? params.formData?.client[asyncSearch?.client || "label"]
                                  : "",
                          }
                        : params.formData;

                    const setLoader = tableData[op]?.noAnimation === true ? setLoadingNewData : setLoading;
                    postData(signal, setLoader, formData, (res) =>
                        setTableData((prev) => ({
                            ...prev,
                            [op]: {
                                data: res?.data[name] || res?.data,
                                isEmty: false,
                                params: {
                                    queryState: params.queryState,
                                    formData,
                                },
                                noAnimation: true,
                            },
                        }))
                    );
                }
            });
            Object.keys(params).length > 0 && localStorage.setItem(op, JSON.stringify(params));
        },
        [params, url]
    );
    // --------------------------------- tOGGLE Search ---------------------------- //
    // --------------------------------- state ---------------------------- //
    const [toggleSearch, setToggleSearch] = useState(false);
    // --------------------------------- state ---------------------------- //
    // --------------------------------- open search if formData search is not empty ---------------------------- //
    useEffect(() => {
        // console.log(dataFromLocalstorage?.formData, "// --------------------------------- state ---------------------------- //", params.formData)
        dataFromLocalstorage?.formData &&
            !Object.values(dataFromLocalstorage?.formData).every((x) => x === null || x === "") &&
            setToggleSearch(true);
    }, []);

    // --------------------------------- open search if formData search is not empty ---------------------------- //
    const handelToggleSearch = useCallback(() => setToggleSearch(!toggleSearch), [toggleSearch]);
    // --------------------------------- tOGGLE Search ---------------------------- //
    // --------------------------------------------------- Filters
    const newOrder = useMemo(() => ({ ASC: "DESC", DESC: "ASC" }), []);
    const handleOrder = useCallback(
        (orderBy) => {
            setParams((prev) => ({
                ...prev,
                queryState: {
                    ...prev.queryState,
                    orderBy,
                    order: prev.queryState.orderBy === orderBy ? newOrder[prev.queryState.order] : "DESC",
                },
            }));
        },
        [newOrder]
    );
    // --------------------------------------------------- Filters
    const handleSubmit = (values) => {
        let data = {};
        for (const [key, value] of Object.entries(values)) {
            if (value) data[key] = value.trim();
        }
        setParams({
            queryState: Object.keys(data).length > 0 ? { ...params?.queryState, page: 1 } : params?.queryState,
            formData: data,
        });
    };
    const handelRefresh = useCallback(
        (formData = null) => {
            setParams({
                formData: formData || {},
                queryState: initialQueryState,
            });
        },
        [initialQueryState]
    );

    const DeleteFromTable = async (
        deleteUrl,
        id,
        setDeleteLoading,
        setDeleteOpen,
        message = "Deleted !!",
        DeleteFetchPost
    ) => {
        try {
            setDeleteLoading(id);
            await API.delete(`${deleteUrl}/${id}`);
            DeleteFetchPost ? DeleteFetchPost() : FetchGet();
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
            // setData(newData);
            setDeleteOpen(false);
            setTableData(tableData);
            setDeleteLoading(false);
        } catch (error) {
            catchFunction(error, setDeleteLoading);
            setDeleteOpen(false);
        }
    };
    const UpdateSamePage = async (updateUrl, formData, setOpen, message) => {
        try {
            setFormLoadingLoading(true);
            const { data: updateData } = await API.patch(`${updateUrl}`, formData);
            setFormLoadingLoading(false);
            setOpen(false);
            setData((prev) =>
                prev.map((d) => {
                    if (d.id === updateData.id) {
                        return updateData;
                    }
                    return d;
                })
            );
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
        } catch (error) {
            catchFunction(error, setFormLoadingLoading);
        }
    };
    const AddSamePage = async (addSamePageUrl, formData, setOpen, message, cb = null) => {
        try {
            setFormLoadingLoading(true);
            const { data: addSamePageData } = await API.post(`${addSamePageUrl}`, formData);
            cb ? cb(addSamePageData) : setData((prev) => [addSamePageData, ...prev]);
            setFormLoadingLoading(false);
            setOpen(false);
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
        } catch (error) {
            catchFunction(error, setFormLoadingLoading);
        }
    };
    return {
        data,
        params,
        toggleSearch,
        loading,
        loadingNewData,
        setData,
        noAnimation: useMemo(() => tableData[op]?.noAnimation, []),
        setParams,
        handelRefresh,
        handelToggleSearch,
        handleOrder,
        handleSubmit,
        FetchGet,
        FetchPost,
        DeleteFromTable,
        UpdateSamePage,
        AddSamePage,
        formLoadingLoading,
    };
};
