/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from "react";
import API from "API/ApiConfig";
import { catchFunction } from "utils/functionHelper";

export const useFetchAsync = (url, options, name, serchBy) => {
  const [data, setData] = useState([]);
  const [error] = useState(false);
  const [loading, setLoading] = useState("");
  const [, setInputValue] = useState("");
  const fetch = useCallback(async (inputValue = null, callback = () => { }) => {

    try {
      setLoading(true);
      const res = await API.get(`${url}?limit=100&page=1`, {
        params: { [serchBy || 'label']: inputValue },
      });
      const formattedData =
        name === "clients"
          ? res?.data[name]?.map((item) => ({
            value: item?.[options[0]] ?? 0,
            label: item?.[options[1]] ?? "",
            city: item?.city?.name,
            ...item
          }))
          : res?.data[name]?.map((item) => ({
            value: item?.[options[0]] ?? 0,
            label: item?.[options[1]] ?? "",
            ...item
          }));

      callback(formattedData);
      setData(formattedData);
      setLoading(false);
    } catch (error) {
      catchFunction(error, setLoading);
    }

  }, []);
  useEffect(() => {
    fetch();
  }, [fetch]);
  const handleInputChange = useCallback((e) => {
    //(e)
    setInputValue(e);
    return e;
  }, []);
  return [data, fetch, handleInputChange, loading, error];
};

