import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    MegaNavBar,
    MegaNavBarItem,
    MegaNavBarItemLink,
    MegaNavBarItems,
    MegaNavBarItemSubMenu,
    MegaNavBarItemSubMenuItem,
    MegaNavBarItemSubMenuItemLink,
    MegaNavBarLogo,
    MegaNavUser,
    MegaNavUserLogout,
    MegaNavUserProfile,
} from "@sobrus-com/sobrus-design-system-v2";
import { useAcl } from "hooks";
import { NavLink as RouteLink, useLocation, useNavigate, matchPath } from "react-router-dom";
import { Navigation } from "values/Navigation";
import { axiosGet } from "../API/API";
import { NotifContext } from "context/NotifContext";
import NotifCard from "components/notifCard/NotifCard";

const Header = ({ user }) => {
    const [showNotifs, setShowNotifs] = useState(false);
    let navigate = useNavigate();
    const { can, canIndex } = useAcl();
    const { pathname } = useLocation();
    const { notifs, setNotifs } = useContext(NotifContext);
    const notifCardRef = useRef(null);
    const notifNavRef = useRef(null);

    const logOut = () => {
        axiosGet("auth/revoke").then(() => {
            localStorage.setItem("isAuthenticatedUser", "false");
            navigate(0);
        });
    };

    useEffect(() => {
        const outsideClick = (e) => {
            if (
                showNotifs &&
                !notifNavRef.current?.contains(e.target) &&
                notifCardRef.current &&
                !notifCardRef.current?.contains(e.target)
            ) {
                setShowNotifs(false);
            }
        };

        document.addEventListener("click", outsideClick);
        return () => document.removeEventListener("click", outsideClick);
    }, [showNotifs]);

    return (
        <header style={{ width: "100%" }}>
            {showNotifs && (
                <Card
                    className="notification-menu-container"
                    cardTitle="Dernières notifications"
                    actionButton={
                        <Button
                            color="tertiary"
                            size="sm"
                            onClick={() => {
                                navigate("/notifications");
                                setShowNotifs(false);
                            }}
                        >
                            Voir tout
                        </Button>
                    }
                    ref={notifCardRef}
                >
                    {notifs?.notifications?.length > 0 &&
                        notifs.notifications?.map((ele) => (
                            <NotifCard
                                key={ele?.id}
                                notifId={ele?.id}
                                isSeen={ele?.isSeen}
                                isClicked={ele?.isClicked}
                                content={ele?.content}
                                context={ele?.context}
                                action={ele?.action}
                                resourceId={ele?.resourceId}
                                time={ele?.createdAt}
                                setShowNotifs={setShowNotifs}
                                setNotifs={setNotifs}
                            />
                        ))}
                </Card>
            )}
            <MegaNavBar>
                <MegaNavBarLogo href="/">
                    <img
                        style={{ display: "block", maxWidth: 120 }}
                        src={require("../assets/sobrusnslogo.svg").default}
                        alt=""
                    />
                </MegaNavBarLogo>
                <div className="premiumText">{user?.subscriptionType === "premium" ? "Pro" : "Free"}</div>
                <MegaNavBarItems>
                    <div
                        style={{ height: "100%" }}
                        onClick={(e) => {
                            setShowNotifs(!showNotifs);
                        }}
                        ref={notifNavRef}
                    >
                        <MegaNavBarItem className="notification-wrapper">
                            <RouteLink
                                to="/notifications"
                                className="notification-container"
                                onClick={(e) => e.preventDefault()}
                                // style={(active) => (active.isActive ? { backgroundColor: "#fdedf1" } : null)}
                            >
                                <img
                                    src={require("../assets/notif-icon.svg").default}
                                    alt=""
                                    style={notifs?.unseenCount > 0 ? { marginRight: "-15px" } : null}
                                    // style={{ marginRight: "-15px" }}
                                />
                                {notifs?.unseenCount > 0 && <div className="notif-number">{notifs?.unseenCount}</div>}
                            </RouteLink>
                        </MegaNavBarItem>
                    </div>
                    {Navigation?.map(
                        (el, index) =>
                            can(el?.does) && (
                                <MegaNavBarItem key={index} className="HeaderItem">
                                    <MegaNavBarItemLink
                                        Tag={RouteLink}
                                        title={el.itemTitle}
                                        to={typeof el.to === "string" ? el?.to : el?.to[canIndex(el?.does)]}
                                        className={
                                            el?.dropDown?.find((ele) =>
                                                matchPath({ path: `${ele?.to}/*` }, pathname)
                                            ) && `active`
                                        }
                                        style={
                                            user?.subscriptionType === "free" && el.premium
                                                ? { opacity: 0.5, pointerEvents: "none" }
                                                : null
                                        }
                                    >
                                        {el.itemTitle}
                                    </MegaNavBarItemLink>
                                    <MegaNavBarItemSubMenu>
                                        {el?.dropDown?.length !== 0 &&
                                            el?.dropDown?.map(
                                                (subEl, idx) =>
                                                    can(subEl?.does) &&
                                                    (user?.subscriptionType !== "free" || !el.premium) && (
                                                        <MegaNavBarItemSubMenuItem key={idx + "subNavItem"}>
                                                            {user?.subscriptionType === "free" && subEl.premium ? (
                                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                <a
                                                                    className="nav-link"
                                                                    style={{
                                                                        opacity: 0.5,
                                                                        backgroundColor: "#fff",
                                                                    }}
                                                                >
                                                                    <div>{subEl.itemTitle}</div>
                                                                </a>
                                                            ) : (
                                                                <MegaNavBarItemSubMenuItemLink
                                                                    Tag={RouteLink}
                                                                    title={subEl.itemTitle}
                                                                    to={
                                                                        typeof subEl.to === "string"
                                                                            ? subEl?.to
                                                                            : subEl?.to[canIndex(subEl?.does)]
                                                                    }
                                                                >
                                                                    {subEl.itemTitle}
                                                                </MegaNavBarItemSubMenuItemLink>
                                                            )}
                                                        </MegaNavBarItemSubMenuItem>
                                                    )
                                            )}
                                    </MegaNavBarItemSubMenu>
                                    {user?.subscriptionType === "free" && el.premium && (
                                        <div className="premiumDropDown" onClick={() => navigate("/premium")}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21.022"
                                                height="18.636"
                                                viewBox="0 0 21.022 18.636"
                                            >
                                                <path
                                                    id="svgexport-10"
                                                    d="M12.074,6.575c-.956,2.675-2.026,3.975-3,4.071A3.125,3.125,0,0,1,6.131,9.117a1.032,1.032,0,0,0-.191-.191,1.911,1.911,0,1,0-1.529.764l.019.229L6,16.111A2.867,2.867,0,0,0,8.768,18.29h8.485a2.867,2.867,0,0,0,2.771-2.179L21.591,9.92a1.032,1.032,0,0,0,.019-.229,1.911,1.911,0,1,0-1.529-.764,1.032,1.032,0,0,0-.191.172,3.273,3.273,0,0,1-2.943,1.548c-.956-.1-1.987-1.414-3-4.071a1.911,1.911,0,1,0-1.873,0Zm6.67,13.148a.956.956,0,0,1,0,1.911H7.278a.956.956,0,0,1,0-1.911Z"
                                                    transform="translate(-2.5 -2.998)"
                                                    fill="#f48240"
                                                />
                                            </svg>
                                            Premium
                                        </div>
                                    )}
                                </MegaNavBarItem>
                            )
                    )}
                </MegaNavBarItems>
                <MegaNavUser fullName={`${user?.firstName} ${user?.lastName}`}>
                    <MegaNavUserProfile onClick={() => navigate("/parametres")} />
                    <MegaNavUserLogout onClick={() => logOut()} />
                </MegaNavUser>
                {/* end user infos  */}
            </MegaNavBar>
        </header>
    );
};

export default Header;
