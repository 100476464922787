import {
    Card,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    InputSelect,
    FormFeedback,
    notify,
    DatePicker,
    Button,
    Tag,
    Container,
    TableContainer,
    Table,
    Th,
    TableHeaderActions,
    TableHeaderTitle,
    TableHeader,
    TableLoader,
    TooltipMenu,
    TooltipMenuItem,
    AsyncSelect,
    TableRefreshBtn,
    TableSearchBtn,
    TableSearchItem,
    SearchBtn,
    TableSearchRow,
    EmptyData,
} from "@sobrus-com/sobrus-design-system-v2";
import React, { forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { format, parseISO } from "date-fns";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import BackwardTitle from "../../../components/backwardTitle/BackwardTitle";
import { axiosGet, axiosPatch, axiosPost } from "../../../API/API";
import { UserContext } from "context/userContext";
import { transfertMessages } from "values/Messages2";
import { useTitle } from "hooks";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import ReactPaginate from "react-paginate";
import useTransfertPaginate from "./useTransfertPaginate";
// import { productsArr } from "./productsArr";

interface initialOrderType {
    productId: "down" | "up" | undefined;
    name: "down" | "up" | undefined;
    supplier: "down" | "up" | undefined;
    packaging: "down" | "up" | undefined;
    quantity: "down" | "up" | undefined;
}

interface defaultValues {
    warehouseId_1: number | null;
    warehouseId_2: number | null;
    administratorId: { value: number; label: string } | null;
    transferDate: Date;
    products: any[];
}

const initialOrder: initialOrderType = {
    productId: undefined,
    name: undefined,
    supplier: undefined,
    packaging: undefined,
    quantity: undefined,
};
// {
//     "transferDate": "2024-01-18 19:08",
//     "administratorId": 25003,
//     "warehouseId_2": 66,
//     "warehouseId_1": 69,
//     "status": "confirmed",
//     "products": {
//         "27063": {
//             "productBatchId": 22433,
//             "quantity": 5,
//             "reason": "mise en quarantaine"
//         },
//         "91316": {
//             "productBatchId": 22382,
//             "quantity": 10,
//             "reason": "mise en quarantaine"
//         }
//     }
// }
const mockData = [
    {
        product: {
            id: 91277,
            name: "CREME LAVANTE 125ML PM",
        },
        supplier: null,
        packaging: null,
        originalWarehouse: {
            warehouse: {
                id: 2,
                name: "cooper tensift",
            },
            batches: [
                {
                    id: 1042,
                    batch: "default_batch_91277",
                    onHandQuantity: 1,
                },
            ],
        },
        destinationWarehouse: {
            warehouse: {
                id: 14,
                name: "Entrepôt n° 5",
            },
            onHandQuantity: 0,
        },
    },
    {
        product: {
            id: 91276,
            name: "SEDASTERIL+ CREME LAVANTE 250ML GM",
        },
        supplier: null,
        packaging: null,
        originalWarehouse: {
            warehouse: {
                id: 2,
                name: "cooper tensift",
            },
            batches: [
                {
                    id: 1041,
                    batch: "default_batch_91276",
                    onHandQuantity: 22,
                },
                {
                    id: 1042,
                    batch: "batch_91276",
                    onHandQuantity: 5,
                },
                {
                    id: 1043,
                    batch: "91276",
                    onHandQuantity: 10,
                },
                {
                    id: 1041,
                    batch: "default_batch_91276",
                    onHandQuantity: 13,
                },
            ],
        },
        destinationWarehouse: {
            warehouse: {
                id: 14,
                name: "Entrepôt n° 5",
            },
            onHandQuantity: 0,
        },
    },
    {
        product: {
            id: 91269,
            name: "SPRAY ANTISEPTIQUE INCOLORE 250ML GM",
        },
        supplier: null,
        packaging: null,
        originalWarehouse: {
            warehouse: {
                id: 2,
                name: "cooper tensift",
            },
            batches: [
                {
                    id: 1040,
                    batch: "default_batch_91269",
                    onHandQuantity: 31,
                },
            ],
        },
        destinationWarehouse: {
            warehouse: {
                id: 14,
                name: "Entrepôt n° 5",
            },
            onHandQuantity: 0,
        },
    },
];

const TransfertCreation = () => {
    const [isFetching, setIsFetching] = useState(true);
    const [users, setUsers] = useState(null);
    const [wareHouses, setWareHouses] = useState<any[]>([]);
    const [propose, setPropose] = useState(null);
    const [submitLocked, setSubmitLocked] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [orderType, setOrderType] = useState(initialOrder);
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { state }: any = useLocation();
    const statusRef = useRef<string | null>(null);
    const idRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const tbodyRef = useRef<HTMLTableSectionElement>(null);
    const { user }: any = useContext(UserContext);
    const {
        register,
        setValue,
        getValues,
        watch,
        reset,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<defaultValues>({
        defaultValues: {
            warehouseId_1: null,
            warehouseId_2: null,
            administratorId: user?.userId,
            transferDate: new Date(),
            products: [],
        },
    });
    useTitle(`Achats - ${id ? "Modification" : "Creation"} proposition de commande`);

    const { fields, remove, replace, update, append } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "products", // unique name for your Field Array
    });

    const warehouseId_1 = watch("warehouseId_1");
    const warehouseId_2 = watch("warehouseId_2");

    const watchFieldArray = watch("products");
    const originalArray =
        fields.length > 0
            ? fields.map((field, index) => {
                  return {
                      ...field,
                      ...watchFieldArray[index],
                      idx: index,
                  };
              })
            : [];

    const [controlledFields, setControlledFields] = useState<any[]>([]);
    const [sortedArray, setSortedArray] = useState<any[]>([]);

    useEffect(() => {
        setSortedArray(originalArray);
        setControlledFields(originalArray);
    }, [watchFieldArray]);

    const { pageCount, handlePageClick, currentItems, handleSliceArray, pageNumber } = useTransfertPaginate(
        fields,
        controlledFields
    );

    const onSubmit = async (values: defaultValues) => {
        let isAtleastOneQty = false;
        values.products.forEach((prod) => {
            if (prod?.quantity > 0) {
                isAtleastOneQty = true;
            }
        });

        if (!isAtleastOneQty)
            notify({
                type: "warning",
                msg: "Veuillez entrer au moins une quantité",
                delay: 5000,
            });

        if (!submitLocked && isAtleastOneQty) {
            setSubmitLocked(true);
            console.log("data", values);
            let data: any = {
                warehouseId_1: values.warehouseId_1,
                warehouseId_2: values.warehouseId_2,
                administratorId: values.administratorId,
                transferDate: format(values.transferDate, "yyyy-MM-dd HH:mm"),
                products: values.products
                    .filter((prod) => prod?.quantity > 0)
                    .map((prod) => ({
                        id: prod?.productId,
                        quantity: Number(prod.quantity),
                        productBatchId: prod?.batch?.productBatchId,
                        reason: prod?.reason,
                    })),
            };

            if (statusRef?.current === "confirmed") {
                data.status = "confirmed";
            }

            console.log("data", data);

            if (id && !state?.duplicate) {
                axiosPatch(`api/stock-transfers/${id}`, data).then((res: any) => {
                    if (res?.data?.id) {
                        notify({
                            type: "success",
                            msg: transfertMessages.UPDATE,
                            delay: 5000,
                        });
                        // if (statusRef?.current === "confirmed") {
                        //     navigate(`/achats/bons-de-commande`);
                        // } else
                        navigate(`/transferts-entrepot/details/${res?.data?.id}`);
                    } else {
                        notify({
                            type: "danger",
                            msg:
                                res?.error?.response?.data?.code < 500
                                    ? res?.error?.response?.data?.message
                                    : transfertMessages.UPDATE_fail,
                            delay: 5000,
                        });
                        setSubmitLocked(false);
                    }
                });
            } else {
                axiosPost("api/stock-transfers", data).then((res: any) => {
                    if (res?.data?.id) {
                        notify({
                            type: "success",
                            msg: transfertMessages.CREATE,
                            delay: 5000,
                        });
                        navigate(`/transferts-entrepot/details/${res?.data?.id}`);
                    } else {
                        notify({
                            type: "danger",
                            msg:
                                res?.error?.response?.data?.code < 500
                                    ? res?.error?.response?.data?.message
                                    : transfertMessages.CREATE_fail,
                            delay: 5000,
                        });
                        setSubmitLocked(false);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (!users) {
            axiosGet<any>(`api/users`).then((res) => {
                setUsers(res?.data?.data);
            });
        }
    }, []);

    useEffect(() => {
        if (searchParams.get("warehouseId_1")) setValue("warehouseId_1", +searchParams.get("warehouseId_1")!);
        if (searchParams.get("warehouseId_2")) setValue("warehouseId_2", +searchParams.get("warehouseId_2")!);
    }, []);

    useEffect(() => {
        axiosGet<any>(`api/warehouses/as-choice?limit=10000`).then((res) => {
            setWareHouses(res?.data?.data);
            res?.data?.data?.forEach((ele: any) => {
                if (ele.isDefault) {
                    // if (!searchParams.get("warehouseId_1")) setValue("warehouseId_1", ele.id);
                    // setIsFetching(false);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (!propose && id) {
            axiosGet<any>(`api/stock-transfers/${id}`).then((res) => {
                setPropose(res?.data);
                reset((formValues) => ({
                    warehouseId_1: res?.data?.fromWarehouse?.id,
                    warehouseId_2: res?.data?.toWarehouse?.id,
                    transferDate: res?.data?.transferDate ? parseISO(res?.data?.transferDate) : new Date(),
                    administratorId: {
                        value: res?.data?.administrator?.id,
                        label: res?.data?.administrator?.firstName + " " + res?.data?.administrator?.lastName,
                    },
                    products: res?.data?.stockTransferProducts?.map((prod: any) => ({
                        name: prod?.product?.name,
                        productId: prod?.product?.id,
                        supplier: prod?.supplier?.name ?? "--",
                        packaging: prod?.packaging,
                        destinationWarehouse: null,
                        originalWarehouse: null,
                        reason: prod?.reason,
                        quantity: prod?.quantity || 0,
                        batch: { productBatchId: prod?.productBatch?.id, batch: prod?.productBatch?.batch },
                    })),
                }));
            });
        }
    }, []);

    useEffect(() => {
        if (warehouseId_1 && warehouseId_2 && !state && !id)
            axiosGet<any>(
                `api/stocks/transfers-data?original_warehouse_id=${warehouseId_1}&destination_warehouse_id=${warehouseId_2}`
            ).then((res) => {
                if (res?.data)
                    replace(
                        res?.data?.flatMap((prod: any) =>
                            prod?.originalWarehouse?.batches?.length > 0
                                ? prod?.originalWarehouse?.batches?.map((ele: any) => ({
                                      ...prod,
                                      name: prod?.product?.name,
                                      productId: prod?.product?.id,
                                      supplier: prod?.supplier ?? "--",
                                      packaging: prod?.packaging,
                                      destinationWarehouse: prod?.destinationWarehouse,
                                      originalWarehouse: {
                                          ...prod?.originalWarehouse,
                                          onHandQuantity: ele?.onHandQuantity,
                                      },
                                      quantity: prod?.quantity || 0,
                                      batch: { productBatchId: ele?.id, batch: ele?.batch },
                                  }))
                                : {
                                      ...prod,
                                      name: prod?.product?.name,
                                      productId: prod?.product?.id,
                                      supplier: prod?.supplier ?? "--",
                                      packaging: prod?.packaging,
                                      destinationWarehouse: prod?.destinationWarehouse,
                                      originalWarehouse: prod?.originalWarehouse,
                                      quantity: prod?.quantity || 0,
                                  }
                        )
                    );
            });
    }, [warehouseId_1, warehouseId_2]);

    useEffect(() => {
        if (state) {
            console.log("state", state);
            reset((formValues) => ({
                ...formValues,
                administratorId: {
                    value: state?.administrator?.id,
                    label: state?.administrator?.firstName + " " + state?.administrator?.lastName,
                },
                products: state?.clientReceivedReturnProducts?.map((prod: any) => ({
                    name: prod?.product?.name,
                    productId: prod?.product?.id,
                    supplier: prod?.supplier?.name ?? "--",
                    packaging: prod?.packaging,
                    destinationWarehouse: null,
                    originalWarehouse: null,
                    quantity: prod?.quantity || 0,
                    batch: { productBatchId: prod?.productBatch?.id, batch: prod?.productBatch?.batch },
                })),
            }));
        }
    }, []);

    function sortCols(key: keyof initialOrderType, isString = false) {
        const tempArr = originalArray.sort(
            isString
                ? orderType[key] === "down" || !orderType[key]
                    ? (a, b) => (a[key]?.toUpperCase() < b[key]?.toUpperCase() ? -1 : 1)
                    : (a, b) => (b[key]?.toUpperCase() > a[key]?.toUpperCase() ? 1 : -1)
                : orderType[key] === "down" || !orderType[key]
                ? (a, b) => +a[key] - +b[key]
                : (a, b) => +b[key] - +a[key]
        );

        setSortedArray(tempArr);
        searchTable(tempArr);
        handlePageClick({ selected: 0 });
        setOrderType((prev) => ({
            ...initialOrder,
            [key]: !prev[key] || prev[key] === "down" ? "up" : "down",
        }));
    }

    const searchTable = (sorted?: any[]) => {
        let tempArr = sorted ? sorted : sortedArray;
        if (nameRef?.current?.value) tempArr = tempArr.filter((filt) => filt.name.includes(nameRef.current!.value));
        if (idRef?.current?.value) tempArr = tempArr.filter((filt) => filt.productId === +idRef.current!.value);
        handleSliceArray(tempArr);
        setControlledFields(tempArr);
        handlePageClick({ selected: 0 });

        return tempArr;
    };

    const handleRefresh = () => {
        setOrderType(initialOrder);
        setControlledFields(originalArray);
        handleSliceArray(originalArray);
        handlePageClick({ selected: 0 });
    };

    const handleEnterKey = (e: any) => {
        if (e.key === "Enter" || e.key === "NumpadEnter") {
            const elements = tbodyRef.current!.querySelectorAll("input");
            console.log("element", elements);
            const activeElementNode = e.target;
            let currentIndex = 0;
            for (let i = 0; i < elements.length; i++) {
                if (elements.item(i) == activeElementNode) {
                    currentIndex = i;
                }
            }

            var nextElement =
                elements?.[currentIndex + 1]?.type === "hidden"
                    ? elements?.[currentIndex + 2]
                    : elements?.[currentIndex + 1];

            console.log("nextElement", nextElement.type);

            if (nextElement) {
                nextElement.focus();
            } else {
                elements?.[0]?.focus();
            }
        }
    };

    return (
        <section className="sns-create-container fade">
            <div className="sns-header">
                {id && !state?.duplicate ? (
                    <BackwardTitle
                        title="Modifier transfert d’entrepôt"
                        subTitle="Détails transfert d’entrepôt"
                        to={`/transferts-entrepot/details/${id}`}
                        isBackward
                    />
                ) : (
                    <BackwardTitle
                        title="Nouveau transfert d’entrepôt"
                        subTitle="Transferts d’entrepôt"
                        to="/transferts-entrepot"
                        isBackward
                    />
                )}
                <div className="sns-header-btns">
                    <Button color="secondary" form="postForm" type="submit">
                        Brouillon
                    </Button>
                    <Button
                        color="primary"
                        form="postForm"
                        type="submit"
                        onClick={() => (statusRef.current = "confirmed")}
                    >
                        Approuver
                    </Button>
                </div>
            </div>
            <form
                id="postForm"
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => e.code === "Enter" && e.preventDefault()}
            >
                <Card style={{ marginBottom: 20 }}>
                    <Container>
                        <Row>
                            <Col>
                                <Controller
                                    control={control}
                                    name="warehouseId_1"
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                                        <FormGroup>
                                            <Label>Entrepôt d&apos;origine*</Label>
                                            <InputSelect
                                                noOptionsMessage="Aucun résultat"
                                                invalid={error !== undefined}
                                                placeholder="Entrepôt d'origine"
                                                value={value}
                                                options={
                                                    wareHouses
                                                        ? wareHouses
                                                              .filter((ele) => ele.id !== warehouseId_2)
                                                              .map((ele) => ({
                                                                  value: ele.id,
                                                                  label: ele.name,
                                                              }))
                                                        : []
                                                }
                                                onChange={(e: any) => onChange(e)}
                                                disabled
                                            />
                                            <FormFeedback invalid={error !== undefined}>
                                                Ce champ est requis
                                            </FormFeedback>
                                        </FormGroup>
                                    )}
                                />
                            </Col>
                            <Col>
                                <Controller
                                    control={control}
                                    name="warehouseId_2"
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                                        <FormGroup>
                                            <Label>Entrepôt de destination*</Label>
                                            <InputSelect
                                                noOptionsMessage="Aucun résultat"
                                                invalid={error !== undefined}
                                                placeholder="Entrepôt de destination"
                                                value={value}
                                                options={
                                                    wareHouses
                                                        ? wareHouses
                                                              .filter((ele) => ele.id !== warehouseId_1)
                                                              .map((ele) => ({
                                                                  value: ele.id,
                                                                  label: ele.name,
                                                              }))
                                                        : []
                                                }
                                                onChange={(e: any) => onChange(e)}
                                                disabled
                                            />
                                            <FormFeedback invalid={error !== undefined}>
                                                Ce champ est requis
                                            </FormFeedback>
                                        </FormGroup>
                                    )}
                                />
                            </Col>
                            <Col>
                                <FormGroup style={{ marginBottom: 0 }}>
                                    <Label>Gestionnaire*</Label>
                                    <Controller
                                        control={control}
                                        name="administratorId"
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, name } }) => (
                                            <InputSelect
                                                noOptionsMessage="Aucun résultat"
                                                invalid={errors?.administratorId !== undefined}
                                                placeholder="Gestionnaire*"
                                                value={value}
                                                options={
                                                    users
                                                        ? Array.from(users, (ele: any) => ({
                                                              value: ele.id,
                                                              label: ele.firstName + " " + ele.lastName,
                                                          }))
                                                        : []
                                                }
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                    <FormFeedback invalid={errors?.administratorId !== undefined}>
                                        Ce champ est requis
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup style={{ marginBottom: 0 }}>
                                    <Label>Date du transfert*</Label>
                                    <Controller
                                        control={control}
                                        name="transferDate"
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, name } }) => (
                                            <DatePicker
                                                invalid={errors?.transferDate !== undefined}
                                                calendarMainColor="primary"
                                                placeholderText="DDate du transfert"
                                                dateFormat="dd-MM-yyyy"
                                                showYearDropdown
                                                selected={value}
                                                onChange={(date: Date) => onChange(date)}
                                            />
                                        )}
                                    />
                                    <FormFeedback invalid={errors?.transferDate !== undefined}>
                                        Ce champ est requis
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderTitle>Liste des produits à transférer</TableHeaderTitle>
                        <TableHeaderActions>
                            <TableRefreshBtn type="button" onClick={handleRefresh} />
                            <TableSearchBtn
                                type="button"
                                onClick={() => setToggleSearch(!toggleSearch)}
                                // open={toggleSearch}
                            />
                        </TableHeaderActions>
                    </TableHeader>
                    <Table>
                        <thead>
                            <tr>
                                <Th order={orderType.productId} onClick={() => sortCols("productId")}>
                                    ID produit
                                </Th>
                                <Th order={orderType.name} onClick={() => sortCols("name", true)}>
                                    Nom du produit
                                </Th>
                                <Th order={orderType.supplier} onClick={() => sortCols("supplier", true)}>
                                    Fournisseur
                                </Th>
                                <Th hideOrder>Colisage</Th>
                                <Th hideOrder>{wareHouses?.find((ele) => ele?.id === warehouseId_1)?.name}</Th>
                                <Th hideOrder>{wareHouses?.find((ele) => ele?.id === warehouseId_2)?.name}</Th>
                                <Th hideOrder>Lot</Th>
                                <Th hideOrder>Motif de transfert</Th>
                                <Th order={orderType.quantity} onClick={() => sortCols("quantity")}>
                                    Qté à alimenter
                                </Th>
                                <Th hideOrder style={{ width: 75 }} />
                            </tr>
                            <TableSearchRow id="simple" open={toggleSearch}>
                                <TableSearchItem>
                                    <Input id="id" name="id" placeholder="ID produit" type="number" ref={idRef} />
                                </TableSearchItem>
                                <TableSearchItem>
                                    <Input id="name" name="name" placeholder="Produit" type="text" ref={nameRef} />
                                </TableSearchItem>
                                <TableSearchItem />
                                <TableSearchItem></TableSearchItem>
                                <TableSearchItem />
                                <TableSearchItem />
                                <TableSearchItem />
                                <TableSearchItem />
                                <TableSearchItem></TableSearchItem>
                                <TableSearchItem>
                                    <SearchBtn onClick={() => searchTable()} />
                                </TableSearchItem>
                            </TableSearchRow>
                        </thead>
                        <tbody id="productsTranfert" ref={tbodyRef} onKeyDown={(e) => handleEnterKey(e)}>
                            {/* {loading ? (
                        <CustomTableauLoading {...tableProps} />
                    ) :  */}

                            {currentItems?.length > 0 &&
                                currentItems.map((field: any, index: number) => (
                                    <tr
                                        // index={index}
                                        // duration={5}
                                        id={`products${field.idx}`}
                                        key={field?.id + "transfert"}
                                        // noAnimation={tableData?.SuppSuggestions?.noAnimation}
                                    >
                                        <td>{field?.productId}</td>
                                        <td>{field?.name}</td>
                                        <td>{field?.supplier?.name ?? "--"}</td>
                                        <td>{field?.packaging ?? "--"}</td>
                                        <td>{field?.originalWarehouse?.onHandQuantity ?? "--"}</td>
                                        <td>{field?.destinationWarehouse?.onHandQuantity ?? "--"}</td>
                                        <td>{field?.batch?.batch ?? "--"}</td>
                                        <td>
                                            {
                                                <Controller
                                                    control={control}
                                                    name={`products.${field.idx}.reason`}
                                                    // rules={{
                                                    //     validate: (value, formValues): any =>
                                                    //         (formValues?.products[field?.idx]?.quantity > 0 && value) ||
                                                    //         formValues?.products[field?.idx]?.quantity <= 0,
                                                    // }}
                                                    render={({
                                                        field: { value, onChange, name },
                                                        fieldState: { error },
                                                    }) => (
                                                        <InputSelect
                                                            noOptionsMessage="Aucun résultat"
                                                            invalid={error !== undefined}
                                                            placeholder="Motif de transfert"
                                                            value={value}
                                                            options={[
                                                                {
                                                                    value: "mise en quarantaine",
                                                                    label: "Mise en quarantaine",
                                                                },
                                                                {
                                                                    value: "réapprovisionnement",
                                                                    label: "Réapprovisionnement",
                                                                },
                                                            ]}
                                                            onChange={(e: any) => onChange(e?.value)}
                                                        />
                                                    )}
                                                />
                                            }
                                        </td>
                                        <td style={{ width: 120 }}>
                                            <Controller
                                                control={control}
                                                name={`products.${field.idx}.quantity`}
                                                render={({
                                                    field: { value, onChange, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <Input
                                                        key={field.idx}
                                                        autoFocus={index === 0}
                                                        value={value}
                                                        onChange={(e: any) =>
                                                            e.target.value >= 0 &&
                                                            e.target.value <= +field?.originalWarehouse?.onHandQuantity
                                                                ? onChange(+e.target.value)
                                                                : onChange(+field?.originalWarehouse?.onHandQuantity)
                                                        }
                                                        type="number"
                                                        onWheel={(event: any) => event.currentTarget.blur()}
                                                        onFocus={(e: any) => e.target.select()}
                                                    />
                                                )}
                                            />
                                        </td>
                                        <td />
                                    </tr>
                                ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={12}>
                                    <ReactPaginate
                                        breakLabel=""
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={0}
                                        marginPagesDisplayed={0}
                                        pageCount={pageCount}
                                        forcePage={pageNumber}
                                        previousLabel="<"
                                        activeClassName="sob-v2-TablePage"
                                        previousClassName="sob-v2-TablePage__btn"
                                        nextClassName="sob-v2-TablePage__btn"
                                        disabledClassName="sob-v2-TablePage__disabled"
                                        containerClassName="custom-pagination-container"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </TableContainer>
            </form>
        </section>
    );
};

export default TransfertCreation;
