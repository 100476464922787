import { Navigate, useSearchParams } from "react-router-dom";

const FailureComponent = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const verifyError = () => {
    if (error === "code1" || error === "code2" || error === "code3") {
      localStorage.clear();
      return <Navigate to="/auth/login" />;
    }
    if (error === "code5" || error === "code6" || error === "code7") {
      return (
        <Navigate
          to={{
            pathname: "/error",
            state: {
              message:
                "Votre compte Sns semble être introuvable ou désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter un administrateur",
              codeError: error,
            },
          }}
        />
      );
    }
  };

  return verifyError();
  //   return <div>ok</div>;
};

export default FailureComponent;
