import "./App.css";
import MainNavigator from "./navigation/MainNavigator";
import * as Sentry from "@sentry/react";
import { hotjar } from "react-hotjar";

if (process.env.REACT_APP_ENV !== "development") {
    Sentry.init({
        dsn: "https://7c4fd86e8ff045038ccd697f11c21208@o4505092585816064.ingest.sentry.io/4505164791414784",
        integrations: [new Sentry.BrowserTracing()],
        beforeSend: (event) => {
            if ([401, 404].includes(+event.exception.values[0].value)) {
                return null;
            }
            return event;
        },
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    hotjar.initialize({ id: 4967925, sv: 6 });
}

function App() {
    return (
        <div className="App">
            <MainNavigator />
        </div>
    );
}

export default App;
