import { Breadcrumb } from "@sobrus-com/sobrus-design-system-v2";
import React from "react";
import { To, useNavigate } from "react-router-dom";
import "./BackwardTitle.scss";

interface props {
    title: string;
    subTitle?: string;
    to?: To | unknown;
    isBackward?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const BackwardTitle: React.FC<props> = ({ title, subTitle, to, isBackward = false, onClick }) => {
    const navigate = useNavigate();

    return (
        <Breadcrumb
            title={title}
            subTitle={subTitle}
            isBackward={isBackward}
            onClick={onClick ? onClick : to ? () => navigate(to, { replace: true }) : undefined}
        />
    );
};

export default BackwardTitle;
