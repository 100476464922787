import React from "react";
import "./404.scss";
import page404 from "assets/logo404.png";
import { Button } from "@sobrus-com/sobrus-design-system-v2";
import { useNavigate } from "react-router";

const Page404 = () => {
    const navigate = useNavigate();
    return (
        <div className="page__404">
            <div className="containet__404">
                <div className="annimated__404">
                    <span>4</span>
                    <img src={page404} alt="" />
                    <span>4</span>
                </div>
                <p className="title">Page introuvable</p>
                <p className="subTitle">La page que vous demandez n'existe pas</p>
                <Button onClick={() => navigate("/")} color="primary">
                    Revenir à l’accueil
                </Button>
            </div>
        </div>
    );
};

export default Page404;
