import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { API_URL } from "../env";

interface CustomAxiosResponse extends AxiosResponse {
    message: string;
}

interface CustomAxiosError extends AxiosError {
    response: CustomAxiosResponse;
}

type ApiResponse<T> = {
    data: T | null;
    error: AxiosError | null;
};

export const axiosGet = async <T = unknown,>(suffix: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    try {
        const response = await axios.get<T>(API_URL + suffix, {
            withCredentials: true,
            ...config,
        });
        return { data: response.data, error: null };
    } catch (error) {
        const axiosError = error as CustomAxiosError;
        console.error(axiosError);
        handleErrors(axiosError);
        return { data: null, error: axiosError };
    }
};

export const axiosPost = async <T = unknown,>(
    suffix: string,
    body?: unknown,
    config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
    try {
        const response = await axios.post<T>(API_URL + suffix, body, {
            withCredentials: true,
            ...config,
        });
        return { data: response.data, error: null };
    } catch (error) {
        const axiosError = error as CustomAxiosError;
        console.error(axiosError);
        handleErrors(axiosError);
        return { data: null, error: axiosError };
    }
};

export const axiosDelete = async <T = unknown,>(
    suffix: string,
    config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
    try {
        const response = await axios.delete<T>(API_URL + suffix, {
            withCredentials: true,
            ...config,
        });
        return { data: response.data, error: null };
    } catch (error) {
        const axiosError = error as CustomAxiosError;
        console.error(axiosError);
        handleErrors(axiosError);
        return { data: null, error: axiosError };
    }
};

export const axiosPut = async <T = unknown,>(
    suffix: string,
    body?: unknown,
    config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
    try {
        const response = await axios.put<T>(API_URL + suffix, body, {
            withCredentials: true,
            ...config,
        });
        return { data: response.data, error: null };
    } catch (error) {
        const axiosError = error as CustomAxiosError;
        console.error(axiosError);
        handleErrors(axiosError);
        return { data: null, error: axiosError };
    }
};

export const axiosPatch = async <T = unknown,>(
    suffix: string,
    body?: unknown,
    config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
    try {
        const response = await axios.patch<T>(API_URL + suffix, body, {
            withCredentials: true,
            ...config,
        });
        return { data: response.data, error: null };
    } catch (error) {
        const axiosError = error as CustomAxiosError;
        console.error(axiosError);
        handleErrors(axiosError);
        return { data: null, error: axiosError };
    }
};

const handleErrors = (error: CustomAxiosError, countryVerification?: boolean) => {
    if (error.response?.message === "code1" && !countryVerification) {
        window.location.reload();
    } else if (error.response?.status === 404) {
        // <Navigate to="/404" replace={true} />;
        // window.location.replace("/404");
    } else if (
        error.response?.status === 401 &&
        error.response.data?.message === "Full authentication is required to access this resource."
    ) {
        window.location.replace("/denied_access");
    }
};
