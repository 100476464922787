import { useEffect } from "react";

export const useHover = (ref, callback) => {
    const current = ref.current;
    useEffect(() => {
        if (ref.current) {
            ref?.current?.addEventListener("mouseenter", (event) => callback(event, "enter"));
            ref?.current?.addEventListener("mouseleave", (event) => callback(event, "leave"));
        }
        return () => {
            if (current) {
                current?.removeEventListener("mouseenter", () => {});
                current?.removeEventListener("mouseleave", () => {});
                
            }
        };
    }, [ref, current, callback]);
};
