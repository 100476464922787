const { useEffect } = require("react");
const { useRef } = require("react");
const { useState } = require("react");

export const useCheckConnexion = () => {
  const SECONDS = 5;
  const [online, setOnLine] = useState(true);
  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(SECONDS);
  const timer = useRef(null);
  setInterval(() => {
    if (navigator.onLine === false) {
      setOnLine(false);
    } else {
      setOnLine(true);
      setSeconds(SECONDS);
    }
  }, [seconds]);

  useEffect(() => {
    timer.current =
      !online &&
      window.setInterval(() => {
        setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    return () => window.clearInterval(timer.current);
  }, [online]);

  useEffect(() => {
    if (seconds === 0) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        setSeconds(SECONDS);
      }, [10000]);
    }
  }, [seconds]);

  const handelCheck = () => {
    setLoader(false);
    setSeconds(SECONDS);
  };

  return { online, seconds, handelCheck, loader };
};
