import Notifications from "pages/notifications/Notifications";
import { lazy } from "react";

const TableauDeBord = lazy(() => import("pages/tableauDeBord/TableauDeBord"));
// const BonsDeCommande = lazy(() => import("pages/bonesDeCommande/BonsDeCommande"));
// const CommandeDetails = lazy(() => import("pages/bonesDeCommande/CommandeDetails"));
const LogErrors = lazy(() => import("pages/logError/LogErrors"));
const LogErrorsDetail = lazy(() => import("pages/logError/LogErrorsDetail"));
// //****Produits *****/
const Produits = lazy(() => import("pages/produits/Produits"));
const ProduitsDetails = lazy(() => import("pages/produits/ProduitsDetails"));
const SuggererProduit = lazy(() => import("pages/produits/SuggererProduit"));
const SuggestionsList = lazy(() => import("pages/produits/SuggestionsList"));
const ProductsMapped = lazy(() => import("pages/produits/ProductsMapped"));
const ProductMappedDetails = lazy(() => import("pages/produits/ProductMappedDetails"));
const EditProduct = lazy(() => import("pages/produits/EditProduct"));
// //****Suppliers *****/
const Suppliers = lazy(() => import("pages/fournisseurs/Suppliers"));
const SuppSuggHistory = lazy(() => import("pages/fournisseurs/SuppSuggHistory"));
const SuggestSupplier = lazy(() => import("pages/fournisseurs/SuggestSupplier"));
const SupplierDetails = lazy(() => import("pages/fournisseurs/SupplierDetails"));
const EditSupplier = lazy(() => import("pages/fournisseurs/EditSupplier"));
const AddSuppPayment = lazy(() => import("pages/fournisseurs/AddSuppPayment"));
// //****Contacts *****/
const Contacts = lazy(() => import("pages/contacts/Contacts"));
const ContactCreation = lazy(() => import("pages/contacts/ContactCreation"));
const ContactDetails = lazy(() => import("pages/contacts/ContactDetails"));
const Cars = lazy(() => import("pages/cars/Cars"));
const CarDetails = lazy(() => import("pages/cars/CarDetails"));
const CarCreation = lazy(() => import("pages/cars/CarCreation"));
// //****Propositions *****/
const OffersOrders = lazy(() => import("pages/achats/offersOrders/OffersOrders"));
const OffersOrdersDetails = lazy(() => import("pages/achats/offersOrders/OffersOrdersDetails"));
// //****Propositions *****/
const OrderProposes = lazy(() => import("pages/achats/orderProposes/OrderProposes"));
const OrderProposeDetails = lazy(() => import("pages/achats/orderProposes/OrderProposeDetails"));
const OrderProposesCreation = lazy(() => import("pages/achats/orderProposes/OrderProposesCreation"));
// //****demande prix *****/
const PriceRequests = lazy(() => import("pages/achats/priceRequests/PriceRequests"));
const PriceRequestsDetails = lazy(() => import("pages/achats/priceRequests/PriceRequestsDetails"));
const PriceRequestsCreation = lazy(() => import("pages/achats/priceRequests/PriceRequestsCreation"));
// //****Receptions *****/
const Receptions = lazy(() => import("pages/achats/receptions/Receptions"));
const ReceptionDetails = lazy(() => import("pages/achats/receptions/ReceptionDetails"));
const ReceptionCreation = lazy(() => import("pages/achats/receptions/ReceptionCreation"));
// //****Supp orders *****/
const SupplierOrders = lazy(() => import("pages/achats/supplierOrders/SupplierOrders"));
const SupplierOrderDetails = lazy(() => import("pages/achats/supplierOrders/SupplierOrderDetails"));
const SuppOrderCreation = lazy(() => import("pages/achats/supplierOrders/SuppOrderCreation"));
// //****Invoices *****/
const SupplierInvoices = lazy(() => import("pages/achats/supplierInvoices/SupplierInvoices"));
const SuppInvoiceDetails = lazy(() => import("pages/achats/supplierInvoices/SuppInvoiceDetails"));
const SuppInvoiceCreation = lazy(() => import("pages/achats/supplierInvoices/SuppInvoiceCreation"));
const AddPayment = lazy(() => import("pages/achats/supplierInvoices/AddPayment"));
// //****Emis *****/
const AvoirsEmis = lazy(() => import("pages/achats/avoirEmis/AvoirsEmis"));
const AvoirEmisCreation = lazy(() => import("pages/achats/avoirEmis/AvoirEmisCreation"));
const AvoirEmisDetails = lazy(() => import("pages/achats/avoirEmis/AvoirEmisDetails"));
// //****Recus *****/
const AvoirsRecus = lazy(() => import("pages/achats/avoirRecus/AvoirsRecus"));
const AvoirRecusCreation = lazy(() => import("pages/achats/avoirRecus/AvoirRecusCreation"));
const AvoirRecusDetails = lazy(() => import("pages/achats/avoirRecus/AvoirRecusDetails"));
// //****Vouchers *****/
const ReleasedVouchers = lazy(() => import("pages/achats/releasedVouchers/ReleasedVouchers"));
const ReleasedVouchersCreation = lazy(() => import("pages/achats/releasedVouchers/ReleasedVouchersCreation"));
const ReleasedVouchersDetails = lazy(() => import("pages/achats/releasedVouchers/ReleasedVouchersDetails"));
// // Offers
const Offers = lazy(() => import("../pages/ventes/Offers/Offers"));
const OfferDetails = lazy(() => import("../pages/ventes/Offers/offerDetails/OfferDetails"));
const OfferCreation = lazy(() => import("../pages/ventes/Offers/offerAdd/OfferCreation"));
const OffersView = lazy(() => import("../pages/ventes/Offers/OffersView"));

export const NavPaths = [
    //  {/* TableauDeBord */}
    {
        path: "/notifications",
        Component: Notifications,
    },
    //  {/* TableauDeBord */}
    {
        path: "/",
        Component: TableauDeBord,
    },
    {
        path: "/tableau-de-bord",
        Component: TableauDeBord,
    },
    // {/* suppliers */}
    {
        path: "/fournisseurs",
        Component: Suppliers,
        canDoes: ["get_suppliers"],
        premium: true,
    },
    {
        path: "/fournisseurs/historique",
        Component: SuppSuggHistory,
        canDoes: [""],
        premium: true,
    },
    {
        path: "/fournisseurs/suggerer-fournisseur",
        Component: SuggestSupplier,
        canDoes: ["get_all_suggestions"],
        premium: true,
    },
    {
        path: "/fournisseurs/suggerer-fournisseur/:id",
        Component: EditSupplier,
        canDoes: ["add_suggestion"],
        premium: true,
    },
    {
        path: "/fournisseurs/details/:id",
        Component: SupplierDetails,
        canDoes: ["get_supplier_by_id"],
        premium: true,
    },
    {
        path: "/fournisseurs/modifier/:id",
        Component: EditSupplier,
        canDoes: ["add_supplier_info"],
        premium: true,
    },
    {
        path: "/fournisseurs/details/:id/ajouter-paiement",
        Component: AddSuppPayment,
        canDoes: ["add_supplier_payment"],
        premium: true,
    },
    // // {/* orders */}
    // // {
    // //     path: "/bons-de-commande",
    // //     Component: BonsDeCommande,
    // //     canDoes: ["get_orders"],
    // // },
    // {
    //     path: "/bons-de-commande:search",
    //     Component: BonsDeCommande,
    //     canDoes: ["get_orders"],
    // },
    // {
    //     path: "/bons-de-commande/:id",
    //     Component: CommandeDetails,
    //     canDoes: ["get-Single_order"],
    // },
    // {/* contacts */}
    {
        path: "/contacts",
        Component: Contacts,
        canDoes: ["get_contacts"],
    },
    {
        path: "/contacts/creation-de-contact",
        Component: ContactCreation,
        canDoes: ["add_contact"],
    },
    {
        path: "/contacts/creation-de-contact/:id",
        Component: ContactCreation,
        canDoes: ["edit_contact"],
    },
    {
        path: "/contacts/:id",
        Component: ContactDetails,
        canDoes: ["get_contact_by_id"],
    },
    // {/* products */}
    {
        path: "/produits",
        Component: Produits,
        canDoes: ["get_sobrus_products"],
    },
    {
        path: "/produits/modifier-produit/:id",
        Component: EditProduct,
        canDoes: ["add_product_data"],
    },
    {
        path: "/produits/list-des-mapping",
        Component: ProductsMapped,
        canDoes: ["mapping_history_products"],
    },
    {
        path: "/produits/list-des-mapping/:id",
        Component: ProductMappedDetails,
        canDoes: ["no_mapping_products"],
    },
    {
        path: "/produits/list-des-suggestions",
        Component: SuggestionsList,
        canDoes: [""],
    },
    {
        path: "/produits/details/:id",
        Component: ProduitsDetails,
        canDoes: ["get_single_product"],
    },
    {
        path: "/produits/suggerer-produit",
        Component: SuggererProduit,
        canDoes: [""],
    },
    {
        path: "/produits/suggerer-produit/:id",
        Component: SuggererProduit,
        canDoes: [""],
    },
    // {/* Achats */}
    //  {/* ---Order propose*/}
    {
        path: "/achats/marketplace-commande",
        Component: OffersOrders,
        canDoes: ["get_all_supplier_purchase_suggestion"],
        premium: true,
    },
    {
        path: "/achats/marketplace-commande/details/:id",
        Component: OffersOrdersDetails,
        canDoes: ["get_all_supplier_purchase_suggestion"],
        premium: true,
    },
    {
        path: "/achats/proposition-commande",
        Component: OrderProposes,
        canDoes: ["get_all_supplier_purchase_suggestion"],
        premium: true,
    },
    {
        path: "/achats/proposition-commande/details/:id",
        Component: OrderProposeDetails,
        canDoes: ["	get_single_supplier_purchase_suggestion"],
        premium: true,
    },
    {
        path: "/achats/proposition-commande/creation",
        Component: OrderProposesCreation,
        canDoes: ["add_supplier_purchase_suggestion"],
        premium: true,
    },
    {
        path: "/achats/proposition-commande/creation/:id",
        Component: OrderProposesCreation,
        canDoes: ["edit_supplier_purchase_suggestions"],
        premium: true,
    },
    //  {/* ---Demande prix*/}
    {
        path: "/achats/demande-prix",
        Component: PriceRequests,
        canDoes: ["app_client_pricingget_supplier_price_request_list"],
        premium: true,
    },
    {
        path: "/achats/demande-prix/details/:id",
        Component: PriceRequestsDetails,
        canDoes: ["app_client_pricingget_single_supplier_price_request"],
        premium: true,
    },
    {
        path: "/achats/demande-prix/creation",
        Component: PriceRequestsCreation,
        canDoes: ["app_client_pricingadd_supplier_price_request"],
        premium: true,
    },
    {
        path: "/achats/demande-prix/creation/:id",
        Component: PriceRequestsCreation,
        canDoes: ["app_client_pricingupdate_supplier_price_request"],
        premium: true,
    },
    // {/* ---supplier orders */}
    {
        path: "/achats/bons-de-commande",
        Component: SupplierOrders,
        canDoes: ["get_supplier_purchase_order"],
        premium: true,
    },
    {
        path: "/achats/bons-de-commande/details/:id",
        Component: SupplierOrderDetails,
        canDoes: ["get_single_supplier_purchase_order"],
        premium: true,
    },
    {
        path: "/achats/bons-de-commande/creation",
        Component: SuppOrderCreation,
        canDoes: ["add_supplier_purchase_order"],
        premium: true,
    },
    {
        path: "/achats/bons-de-commande/creation/:id",
        Component: SuppOrderCreation,
        canDoes: ["edit_supplier_purchase_order"],
        premium: true,
    },
    // {/* ---Receptions */}
    {
        path: "/achats/bons-de-reception",
        Component: Receptions,
        canDoes: ["get_all_supplierdeliverynotes"],
        premium: true,
    },
    {
        path: "/achats/bons-de-reception/details/:id",
        Component: ReceptionDetails,
        canDoes: ["get_single_supplierdeliverynote"],
        premium: true,
    },
    {
        path: "/achats/bons-de-reception/creation",
        Component: ReceptionCreation,
        canDoes: ["supplier_delivery_note"],
        premium: true,
    },
    {
        path: "/achats/bons-de-reception/creation/:id",
        Component: ReceptionCreation,
        canDoes: ["edit_supplierdeliverynote"],
        premium: true,
    },
    //  {/* ---supplier invoices */}
    {
        path: "/achats/factures-fournisseur",
        Component: SupplierInvoices,
        canDoes: ["get_supplier_invoice"],
        premium: true,
    },
    {
        path: "/achats/factures-fournisseur/details/:id",
        Component: SuppInvoiceDetails,
        canDoes: ["get_single_supplier_invoice"],
        premium: true,
    },
    {
        path: "/achats/factures-fournisseur/creation",
        Component: SuppInvoiceCreation,
        canDoes: ["add_supplier_invoice"],
        premium: true,
    },
    {
        path: "/achats/factures-fournisseur/creation/:id",
        Component: SuppInvoiceCreation,
        canDoes: ["edit_supplier_invoice"],
        premium: true,
    },
    {
        path: "/achats/factures-fournisseur/ajouter-paiement/:id",
        Component: AddPayment,
        canDoes: ["add_supplier_payment"],
        premium: true,
    },
    //  {/* ---supplier avoir emis */}
    {
        path: "/achats/avoir-emis",
        Component: AvoirsEmis,
        canDoes: ["get_all_supplier_issued_return"],
        premium: true,
    },
    {
        path: "/achats/avoir-emis/details/:id",
        Component: AvoirEmisDetails,
        canDoes: ["get_single_supplier_issued_return"],
        premium: true,
    },
    {
        path: "/achats/avoir-emis/creation",
        Component: AvoirEmisCreation,
        canDoes: ["add_supplier_issued_return"],
        premium: true,
    },
    {
        path: "/achats/avoir-emis/creation/:id",
        Component: AvoirEmisCreation,
        canDoes: ["edit_supplier_issued_return"],
        premium: true,
    },
    //  {/* ---supplier avoir reçu */}
    {
        path: "/achats/avoir-recu",
        Component: AvoirsRecus,
        canDoes: ["get_all_supplier_received_return"],
        premium: true,
    },
    {
        path: "/achats/avoir-recu/details/:id",
        Component: AvoirRecusDetails,
        canDoes: ["get_single_supplier_received_return"],
        premium: true,
    },
    {
        path: "/achats/avoir-recu/creation",
        Component: AvoirRecusCreation,
        canDoes: ["add_supplier_received_return"],
        premium: true,
    },
    {
        path: "/achats/avoir-recu/creation/:id",
        Component: AvoirRecusCreation,
        canDoes: ["edit_supplier_received_returns"],
        premium: true,
    },
    //  {/* ---supplier voucher release */}
    {
        path: "/achats/bons-de-sortie",
        Component: ReleasedVouchers,
        canDoes: ["get_release_voucher"],
        premium: true,
    },
    {
        path: "/achats/bons-de-sortie/details/:id",
        Component: ReleasedVouchersDetails,
        canDoes: ["get_single_release_voucher"],
        premium: true,
    },
    {
        path: "/achats/bons-de-sortie/creation",
        Component: ReleasedVouchersCreation,
        canDoes: ["add_release_voucher"],
        premium: true,
    },
    {
        path: "/achats/bons-de-sortie/creation/:id",
        Component: ReleasedVouchersCreation,
        canDoes: ["edit_release_voucher"],
        premium: true,
    },
    // {/* Ventes -> Offers */}
    {
        path: "/ventes/offers",
        Component: Offers,
        canDoes: ["get_all_offers"],
        premium: false,
    },
    {
        path: "/ventes/offers/marketplace",
        Component: OffersView,
        canDoes: ["get_all_offers"],
        premium: false,
    },
    {
        path: "/ventes/offers/details/:id",
        Component: OfferDetails,
        canDoes: ["get_offer_by_id"],
        premium: false,
    },
    {
        path: "/ventes/offers/create",
        Component: OfferCreation,
        canDoes: ["add_offer"],
        premium: false,
    },
    {
        path: "/ventes/offers/create/:id",
        Component: OfferCreation,
        canDoes: ["update_offer"],
        premium: false,
    },
    //{/* cars */}
    {
        path: "/parc-auto",
        Component: Cars,
        canDoes: ["get_vehicles"],
        premium: true,
    },
    {
        path: "/parc-auto/details/:id",
        Component: CarDetails,
        canDoes: ["get_single_vehicle"],
        premium: true,
    },
    {
        path: "/parc-auto/creation",
        Component: CarCreation,
        canDoes: ["add_vehicle"],
        premium: true,
    },
    {
        path: "/parc-auto/creation/:id",
        Component: CarCreation,
        canDoes: ["edit_vehicle_info"],
        premium: true,
    },
    //  {/* logs */}
    {
        path: "/logs-erreurs",
        Component: LogErrors,
        canDoes: ["get_all_log"],
    },
    {
        path: "/logs-erreurs/:id",
        Component: LogErrorsDetail,
        canDoes: ["get_log_by_id"],
    },
];
