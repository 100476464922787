/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import API from "API/ApiConfig";
import { useParams } from "react-router-dom";
import { notify } from "@sobrus-com/sobrus-design-system-v2";
import { DataContext } from "context/DataContext";
import { isEqual } from "lodash";
import { catchFunction } from "utils/functionHelper";

export const useConditionalFetch = (
    handelRefresh,
    initialQueryState,
    op,
    url,
    dataFetching,
    query,
    search,
    name = ""
) => {
    const [loading, setLoading] = useState(false);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const [pages, setPages] = useState(0);
    const { tableData, setTableData } = useContext(DataContext);
    const [data, setData] = useState([]);
    const [formLoading, setFormLoading] = useState(false);

    const FetchGet = useCallback(
        async (signal = null, cityValueSearch = null) => {
            Object.keys(dataFetching?.formData).map(
                (key) => dataFetching?.formData[key] === "" && delete dataFetching?.formData[key]
            );
            let formData = dataFetching.formData;
            const tableFormData = tableData[op]?.formData;
            let queryState = dataFetching.queryState;
            if (
                tableData[op]?.isEmty === false &&
                isEqual(dataFetching.formData, tableFormData) &&
                isEqual(queryState, tableData[op].queryState)
            ) {
                try {
                    // {name: "sdqsd", title: {value: "erer", label: "eze"}}
                    tableData[op].noAnimation = true;
                    setData(tableData[op].data);
                    setPages(tableData[op].pages);
                    setLoadingNewData(true);
                    if (tableData[op].data?.length === 0) {
                        handelRefresh();
                        formData = {};
                        queryState = initialQueryState;
                        localStorage.setItem(search, JSON.stringify(formData));
                        localStorage.setItem(query, JSON.stringify(queryState));
                    }
                    const fData = {};
                    for (const [key, value] of Object.entries(formData)) {
                        // console.log(`keyValue: ${key}: ${typeof value}`);
                        if (typeof value === "object") {
                            fData[key] = formData[key].value;
                        } else fData[key] = formData[key];
                    }
                    const res = await API.get(`${url}`, {
                        params: {
                            ...queryState,
                            ...fData,
                        },
                        signal,
                    });
                    setLoadingNewData(false);
                    setData(res?.data[name] || res?.data);
                    setPages(Math.ceil(res?.data?.totalItems / queryState?.limit));
                    setTableData((prev) => ({
                        ...prev,
                        [op]: {
                            ...prev[op],
                            data: res?.data[name] || res?.data,
                            pages: Math.ceil(res?.data?.totalItems / queryState?.limit),
                        },
                    }));
                    localStorage.removeItem(search);
                    Object.keys(formData).length > 0 && localStorage.setItem(search, JSON.stringify(formData));
                    localStorage.removeItem(query);
                    Object.keys(queryState).length > 0 && localStorage.setItem(query, JSON.stringify(queryState));
                } catch (error) {
                    catchFunction(error, setLoadingNewData);
                }
            } else {
                try {
                    setLoading(true);
                    const fData = {};
                    console.log(formData);
                    for (const [key, value] of Object.entries(formData)) {
                        // console.log(`keyValue: ${key}: ${typeof value}`);
                        if (typeof value === "object") {
                            fData[key] = formData[key].value;
                        } else fData[key] = formData[key];
                    }
                    const res = await API.get(`${url}`, {
                        params: {
                            ...queryState,
                            ...fData,
                        },
                        signal,
                    });
                    console.log(res);
                    setData(res?.data[name] || res?.data);
                    setPages(Math.ceil(res?.data?.totalItems / queryState?.limit));
                    setLoading(false);
                    setTableData((prev) => ({
                        ...prev,
                        [op]: {
                            data: res?.data[name] || res?.data,
                            isEmty: false,
                            pages: Math.ceil(res?.data?.totalItems / queryState?.limit),
                            queryState: queryState,
                            formData: dataFetching?.formData,
                            noAnimation: false,
                        },
                    }));
                    localStorage.removeItem(search);
                    Object.keys(formData).length > 0 && localStorage.setItem(search, JSON.stringify(formData));
                    localStorage.removeItem(query);
                    Object.keys(queryState).length > 0 && localStorage.setItem(query, JSON.stringify(queryState));
                } catch (error) {
                    catchFunction(error, setLoading);
                }
            }
        },
        [name, dataFetching, url]
    );
    const Delete = async (url, id, message, setLoading, setDeleteOpen) => {
        try {
            setLoading(id);
            await API.delete(`${url}/${id}`);
            const newData = data.filter((d) => d.id !== id);
            setData(newData);
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
            setDeleteOpen && setDeleteOpen(false);
            op.map((o) => {
                if (o in tableData) tableData[o].data = newData;
                return o;
            });
            setTableData(tableData);
            setLoading(false);
        } catch (error) {
            catchFunction(error, setLoading);
            setDeleteOpen && setDeleteOpen(false);
        }
    };
    const UpdateSamePage = async (url, formData, setOpen, setId, message, functionHelper = false) => {
        try {
            setFormLoading(true);
            const { data } = await API.patch(`${url}`, formData);
            setFormLoading(false);
            setOpen(false);
            setId(false);
            functionHelper
                ? functionHelper(data)
                : setData((prev) =>
                      prev.map((d) => {
                          if (d.id === data.id) {
                              return data;
                          }
                          return d;
                      })
                  );
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
        } catch (error) {
            catchFunction(error, setFormLoading);
        }
    };
    const AddSamePage = async (url, formData, setOpen, message, functionHelper = false) => {
        try {
            setFormLoading(true);
            const { data } = await API.post(`${url}`, formData);
            setFormLoading(false);
            setOpen && setOpen(false);
            functionHelper ? functionHelper(data) : setData((prev) => [data, ...prev]);
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
        } catch (error) {
            catchFunction(error, setFormLoading);
        }
    };
    return {
        data,
        setData,
        loading,
        setLoading,
        pages: useMemo(() => pages, [pages]),
        setPages,
        // FetchPost,
        FetchGet,
        Delete,
        loadingNewData: useMemo(() => loadingNewData, [loadingNewData]),
        formLoading,
        AddSamePage,
        UpdateSamePage,
    };
};

export const useGetOne = (url, propsId = null) => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                if (propsId || id) {
                    const res = await API.get(`${url}`);
                    setData(res?.data);
                }
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        };

        fetch();
    }, [url, id, propsId]);
    return { data, loading, setLoading, setData };
};
export const usePostOne = (url, formData) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const res = await API.post(`${url}`, formData);
                setData(res?.data);
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        };

        fetch();
    }, [url]);
    return { data, loading, setData };
};
