import axios from "axios";
import axiosRetry from "axios-retry";
import { API_URL } from "../env";

const AXIOS = axios.create({
    baseURL: `${API_URL}`,
    responseType: "json",
    withCredentials: true,
});

AXIOS.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 404) {
            // window.location.replace("/404");
            return Promise.reject(error);
        } else if (
            error?.response?.status === 401 &&
            error?.response?.data?.message === "Full authentication is required to access this resource."
        ) {
            // <Navigate to="/denied_access" replace={true} />;
            window.location.replace("/denied_access");
        }
        return Promise.reject(error);
    }
);

// for repeat the request 3 time
axiosRetry(AXIOS, { retryDelay: axiosRetry.exponentialDelay });
axiosRetry(AXIOS, { retries: 3 });

export default AXIOS;
