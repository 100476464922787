import React from "react";
import "./style.scss";
import { useCheckConnexion } from "./hooks";
import { MdOutlineCloudOff } from "react-icons/md";
const ConnexionDetector = () => {
  const { online, seconds, handelCheck, loader } = useCheckConnexion();
  return (
    <div className="cnx__detector">
      {!online && (
        <div className="offline">
          <div className="parts part__1">
            <MdOutlineCloudOff size={22} />
            <span>Vous n’êtes pas connecté </span>
          </div>
          <div className="parts part__2">
            {loader ? (
              <span>Reconnexion ...</span>
            ) : (
              <span>Connexion dans {seconds} s…</span>
            )}
            <span className="ressayer" onClick={() => handelCheck()}>
              Ressayer
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnexionDetector;
