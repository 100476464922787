import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DataTypes<T> {
	[key: string]: { data: T; loading: boolean; error: string; loadingNewData: boolean; noAnimation: boolean };
}

interface DataState<T> {
	dataTables: DataTypes<T>;
}

const initialState: Readonly<DataState<any>> = {
	dataTables: {},
};

// const keyValue = ()

const tableData = createSlice({
	name: 'dataTables',
	initialState,
	reducers: {
		createDataStorageName: (state, action: PayloadAction<string>) => {
			state.dataTables = {
				...state.dataTables,
				[action.payload]: { data: [], loading: true, loadingNewData: false, noAnimation: false, error: '' },
			};
		},
		loadNewData: (state, action: PayloadAction<string>) => {
			state.dataTables[action.payload].loadingNewData = true;
		},
		// isLoading: (state, action) => {
		//     state.dataTables[action.payload].loading = true;
		// },
		setData: (state, action: PayloadAction<any>) => {
			const [key] = Object.keys(action.payload);
			const [value] = Object.values(action.payload);
			state.dataTables[key].loading = false;
			state.dataTables[key].loadingNewData = false;
			state.dataTables[key].data = value;
			state.dataTables[key].noAnimation = true;
		},

		setDataLoading: (state, action: PayloadAction<string>) => {
			state.dataTables[action.payload].loading = false;
			state.dataTables[action.payload].loadingNewData = false;
		},
	},
});

export const { setData, setDataLoading, createDataStorageName, loadNewData } = tableData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default tableData.reducer;
