import logo from "assets/logo.svg";
import unauthoriz from "assets/unauthoriz.png";
import { Link } from "react-router-dom";
import "./sns__unauthorized.scss";
const ErrorPage = () => {
  return (
    <div className="sns__error">
      <div className="part__2">
        <img style={{ display: "block", width: 148 }} src={logo} alt="" />
        <p className="oops">Ooops !</p>
        <p>
          Votre compte semble être introuvable ou désactivé, si vous ignorez la
          raison de cette erreur vous pouvez contacter le service administratif
          de Sobrus
        </p>
        <button>
          <Link to="/">Quitter</Link>
        </button>
      </div>
      <div className="image">
        <img src={unauthoriz} alt="" />
      </div>
    </div>
  );
};

export default ErrorPage;
