import { notify, Tag } from "@sobrus-com/sobrus-design-system-v2";
import { isEqual } from "lodash";

let globalCssModule;

export function mapToCssModules(className = "", cssModule = globalCssModule) {
    if (!cssModule) return className;
    return className
        .split(" ")
        .map((c) => cssModule[c] || c)
        .join(" ");
}

export const compareProps = (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
};

export const catchFunction = (error, setLoading = false) => {
    setLoading && setLoading(false);
    if (error?.message === "canceled") {
        return 0;
    } else if (error?.response?.data?.message?.startsWith("code")) {
        localStorage.clear();
        window.location.reload();
    } else {
        if (error?.response?.data?.message || error?.response?.data)
            notify({
                type: "danger",
                msg: error?.response?.data?.message || error?.response?.data,
                delay: 5000,
            });
    }
};
export const getAvoirType = (status) => (
    <>
        {status === "Bon état" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC">
                Bon état
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C">
                Mauvais état
            </Tag>
        )}
    </>
);

export const getStatus = (status) => (
    <>
        {status === "confirmed" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC" size="sm">
                Confirmé
            </Tag>
        ) : status === "draught" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                Brouillon
            </Tag>
        ) : status === "brouillon" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                Brouillon
            </Tag>
        ) : status === "pending" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                En Attente
            </Tag>
        ) : status === "expédié" ? (
            <Tag bgColor="#E3F7F5" textColor="#1BBAAC" size="sm">
                Expédié
            </Tag>
        ) : status === "en attente d'expédition" ? (
            <Tag bgColor="#F9EBCF" textColor="#D89300" size="sm">
                En attente d'expédition
            </Tag>
        ) : status === "en cours de livraison" ? (
            <Tag bgColor="#EFFDEC" textColor="#3BE014" size="sm">
                En cours de livraison
            </Tag>
        ) : status === "livrée" ? (
            <Tag bgColor="#EAF8E7" textColor="#3BE014" size="sm">
                Livrée
            </Tag>
        ) : status === "pending" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                En Attente
            </Tag>
        ) : status === "accounted" ? (
            <Tag bgColor="#E2EFFC" textColor="#123E68" size="sm">
                Comptabilisé
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C" size="sm">
                Annulé
            </Tag>
        )}
    </>
);

export const getBLStatus = (status) => (
    <>
        {status === "confirmed" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC" size="sm">
                Confirmé
            </Tag>
        ) : status === "draught" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                Brouillon
            </Tag>
        ) : status === "brouillon" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                Brouillon
            </Tag>
        ) : status === "en attente de préparation" ? (
            <Tag bgColor="#FFEFE7" textColor="#7C3111" size="sm">
                En attente de préparation
            </Tag>
        ) : status === "en attente de contrôle" ? (
            <Tag bgColor="#DAE0E7" textColor="#2B4660" size="sm">
                En attente de contrôle
            </Tag>
        ) : status === "en attente d'expédition" ? (
            <Tag bgColor="#FFF6ED" textColor="#BE3610" size="sm">
                En attente d'expédition
            </Tag>
        ) : status === "en attente de sortie" ? (
            <Tag bgColor="#E2EFFC" textColor="#123E68" size="sm">
                En attente de sortie
            </Tag>
        ) : status === "en cours de livraison" ? (
            <Tag bgColor="#F2EDFA" textColor="#6E4097" size="sm">
                En cours de livraison
            </Tag>
        ) : status === "livré" ? (
            <Tag bgColor="#D9FCDF" textColor="#08733F" size="sm">
                Livré
            </Tag>
        ) : status === "pending" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A" size="sm">
                En Attente
            </Tag>
        ) : status === "accounted" ? (
            <Tag bgColor="#E2EFFC" textColor="#123E68" size="sm">
                Comptabilisé
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C" size="sm">
                Annulé
            </Tag>
        )}
    </>
);

export const requestStatus = (status) => (
    <>
        {status === "Résolu" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC">
                Résolu
            </Tag>
        ) : status === "Ouvert" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                Ouvert
            </Tag>
        ) : status === "Assigné" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                Assigné
            </Tag>
        ) : status === "Nouveau" ? (
            <Tag bgColor="rgb(237, 251, 254)" textColor="rgb(2, 194, 237)">
                Nouveau
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C">
                Archivé
            </Tag>
        )}
    </>
);

export const requestPriorty = (status) => (
    <>
        {status === "Normale" ? (
            <Tag bgColor="rgb(237, 251, 254)" textColor="rgb(2, 194, 237)">
                Normale
            </Tag>
        ) : status === "Critique" ? (
            <Tag bgColor="rgb(250, 242, 236)" textColor="rgb(255, 157, 74)">
                Critique
            </Tag>
        ) : (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                Urgente
            </Tag>
        )}
    </>
);

export const EmailSettingStatus = (status) => (
    <>
        {status ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC">
                Actif
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C">
                Inactif
            </Tag>
        )}
    </>
);
export const RaportPaymentStatus = (status) => (
    <>
        {status === "confirmed" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC">
                Validée
            </Tag>
        ) : status === "pending" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                En attente
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C">
                Annulée
            </Tag>
        )}
    </>
);

export const statusDefaults = (status) => (
    <>
        {status === "confirmed" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC">
                Validée
            </Tag>
        ) : status === "draught" ? (
            <Tag bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                En attente
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C">
                Annulée
            </Tag>
        )}
    </>
);

export const paymentStatus = (status) => (
    <>
        {status === "unpaid" ? (
            <Tag className="tag__paymentStatus" bgColor="#F9D2D3" textColor="#CC3244">
                Non-payé
            </Tag>
        ) : status === "partiallyPaid" ? (
            <Tag className="tag__paymentStatus" bgColor="#FAE9C7" textColor="#D39000">
                Partiellement Payé
            </Tag>
        ) : status === "paid" ? (
            <Tag className="tag__paymentStatus" bgColor="#D9FCDF" textColor="#0EA45F">
                Payé
            </Tag>
        ) : (
            <></>
        )}
    </>
);
export const getOrderStatus = (status) => (
    <>
        {status === "en cours de traitement" ? (
            <Tag className="tag__orderstatus" bgColor="#FEF9EE" textColor="#EAA20E">
                En cours de traitement
            </Tag>
        ) : status === "en préparation" ? (
            <Tag className="tag__orderstatus" bgColor="#E1F3F4" textColor="#008E7C">
                En préparation
            </Tag>
        ) : status === "en attente de traitement" ? (
            <Tag className="tag__orderstatus" bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                En attente de traitement
            </Tag>
        ) : status === "en attente de confirmation" ? (
            <Tag className="tag__orderstatus" bgColor="#EFFDEC" textColor="#3BE014">
                En attente de confirmation
            </Tag>
        ) : status === "en cours de livraison" ? (
            <Tag className="tag__orderstatus" bgColor="#EFFDEC" textColor="#3BE014">
                En cours de livraison
            </Tag>
        ) : status === "en cours d'expédition" ? (
            <Tag className="tag__orderstatus" bgColor="#E2EFFC" textColor="#55ACED">
                En cours d'expédition
            </Tag>
        ) : status === "contrôlée" ? (
            <Tag className="tag__orderstatus" bgColor="#EAF8E7" textColor="#3BE014">
                Contrôlée
            </Tag>
        ) : status === "livrée" ? (
            <Tag className="tag__orderstatus" bgColor="#EAF8E7" textColor="#3BE014">
                Livrée
            </Tag>
        ) : status === "reçue" ? (
            <Tag className="tag__orderstatus" bgColor="#E2EFFC" textColor="#123E68">
                Reçue
            </Tag>
        ) : status === "annulée" ? (
            <Tag className="tag__orderstatus" bgColor="#FAE8E8" textColor="#FF1A1A">
                Annulée
            </Tag>
        ) : status === "rejetée" ? (
            <Tag className="tag__orderstatus" bgColor="#FAE8E8" textColor="#FF1A1A">
                Rejetée
            </Tag>
        ) : (
            <Tag className="tag__orderstatus" bgColor="rgba(229,195,10,.08)" textColor="#E5C30A">
                Brouillon
            </Tag>
        )}
    </>
);
export const getPaidbackStatus = (status) => (
    <>
        {status ? (
            <Tag className="tag__orderstatus" bgColor="#D9FCDF" textColor="#0EA45F" size="sm">
                Oui
            </Tag>
        ) : (
            <Tag className="tag__orderstatus" bgColor="#F9D2D3" textColor="#CC3244" size="sm">
                Non
            </Tag>
        )}
    </>
);

export const getPaymentStatus = (status) => (
    <>
        {status === "paid" ? (
            <Tag className="tag__orderstatus" bgColor="#D9FCDF" textColor="#0EA45F" size="sm">
                Payé
            </Tag>
        ) : status === "unpaid" ? (
            <Tag className="tag__orderstatus" bgColor="#F9D2D3" textColor="#CC3244" size="sm">
                Non payé
            </Tag>
        ) : (
            <Tag className="tag__orderstatus" bgColor="#FAE9C7" textColor="#D39000" size="sm">
                Partiellement payé
            </Tag>
        )}
    </>
);

export const getPaymentHistoryStatus = (status) => (
    <>
        {status === "confirmed" ? (
            <Tag bgColor="rgba(27,186,173,.08)" textColor="#1BBAAC">
                Confirmé
            </Tag>
        ) : status === "canceled" ? (
            <Tag bgColor="#FAEFE9" textColor="#F5792C">
                Annulé
            </Tag>
        ) : status === "in_wallet" ? (
            <Tag bgColor="#D9FCDF" textColor="#08733F">
                En portfeuille
            </Tag>
        ) : status === "in_bank" ? (
            <Tag bgColor="#08733F" textColor="#ffffff">
                En banque
            </Tag>
        ) : (
            <Tag bgColor="#FAEFE9" textColor="#F5792C"></Tag>
        )}
    </>
);

export const displayStock = (stock) =>
    !stock || +stock === 0 ? (
        <Tag minWidth={20} size="sm" textColor="#D39000" bgColor="#FAE9C7">
            {stock}
        </Tag>
    ) : +stock > 0 ? (
        <Tag minWidth={20} size="sm" textColor="#0EA45F" bgColor="#D9FCDF">
            {stock}
        </Tag>
    ) : (
        <Tag minWidth={20} size="sm" textColor="#E74C3C" bgColor="#F9D2D3">
            {stock}
        </Tag>
    );

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const currencyChanger = (country) => {
    return country === "fr" ? "£" : "Dhs";
};
