import React, { useState, useLayoutEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import HomeNavigation from "./HomeNavigation";
import { DataContextProvider } from "context/DataContext";
import "../styles/styles.scss";
import { axiosGet } from "API/API";
import { UserContext } from "context/userContext";
import { NotifContextProvider } from "context/NotifContext";
import { Provider } from "react-redux";
import { store } from "store/store";

const MainNavigator = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(localStorage.getItem("isAuthenticatedUser"));
    const [user, setUser] = useState(null);

    const [isFetching, setIsFetching] = useState(true);
    useLayoutEffect(() => {
        if (!isLogin && isFetching && isAuthenticatedUser === "true") {
            axiosGet("authenticatedUser").then((res) => {
                if (res?.data) {
                    setIsLogin(true);
                    setUser(res?.data);
                } else {
                    localStorage.setItem("isAuthenticatedUser", "false");
                    setIsAuthenticatedUser("false");
                }

                setIsFetching(false);
            });
        }
    }, [isLogin, isFetching, isAuthenticatedUser]);

    return (
        <BrowserRouter>
            <Provider store={store}>
                <DataContextProvider>
                    <UserContext.Provider
                        value={{
                            isLogin,
                            setIsLogin,
                            user,
                            isFetching,
                            isAuthenticatedUser,
                            setIsAuthenticatedUser,
                        }}
                    >
                        {isLogin ? (
                            <NotifContextProvider>
                                <HomeNavigation />
                            </NotifContextProvider>
                        ) : (
                            <HomeNavigation />
                        )}
                    </UserContext.Provider>
                </DataContextProvider>
            </Provider>
        </BrowserRouter>
    );
};

export default MainNavigator;
