import { useEffect, useState } from "react";

const useTransfertPaginate = (fields: any, controlledFields: any) => {
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setpageCount] = useState(Math.ceil(fields.length / 30));

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + 30;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
        if (event.selected < pageCount) {
            setPageNumber(event.selected);
            const newOffset = (event.selected * 30) % controlledFields.length;
            // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
            setItemOffset(newOffset);
        }
    };

    const handleSliceArray = (controlledFields: any) => {
        setpageCount(Math.ceil(controlledFields.length / 30));
        setCurrentItems(
            controlledFields?.slice(itemOffset, endOffset).map((prod: any, index: number) => ({
                ...prod,
                id: prod?.id,
                name: prod?.name,
                productId: prod?.productId,
                supplier: prod?.supplier,
                packaging: prod?.packaging,
                destinationWarehouse: prod?.destinationWarehouse,
                originalWarehouse: prod?.originalWarehouse,
                batches: prod?.batches,
                quantity: prod?.quantity || 0,
                idx: prod?.idx,
            }))
        );
    };

    useEffect(() => {
        handleSliceArray(controlledFields);
    }, [controlledFields, itemOffset]);

    return { pageCount, handlePageClick, currentItems, handleSliceArray, pageNumber };
};

export default useTransfertPaginate;
