import { notify } from "@sobrus-com/sobrus-design-system-v2";
import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router";

export const useNotify = () => {
    const navigationType = useNavigationType();
    const location = useLocation();
    useEffect(() => {
        if (navigationType === "PUSH")
            if (location?.state?.detail === "success") {
                notify({
                    type: "success",
                    msg: location?.state?.message,
                    delay: 5000,
                });
            }
    }, [location, navigationType]);
};
