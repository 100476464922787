import { useState } from "react";

const useTablePagination = (arr, tableCells, setFetching) => {
    const [pageNumber, setPageNumber] = useState(1);

    let displayPosts = [];

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
        setFetching(true);
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setFetching(true);
        }
    };

    if (arr && arr?.length > 0) {
        displayPosts = arr?.map((ele) => tableCells(ele));
    }

    return { pageNumber, setPageNumber, prevPage, nextPage, displayPosts };

    // const pagesVisited = pageNumber * orderPerPage;

    // let displayPosts = [];
    // let pageCount = 0;

    // const changePage = ({ selected }) => {
    //     setPageNumber(selected);
    // };

    // return { displayPosts, pageCount, changePage };
};

export default useTablePagination;
