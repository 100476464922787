import { useEffect, useLayoutEffect, useState } from "react";
import API from "API/ApiConfig";

import { catchFunction } from "utils/functionHelper";
export const useStats = (url, formData) => {
    const [data, setData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pieDataSet, setPieData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    useLayoutEffect(() => {
        const fetch = async () => {
            const asyncFormData =
                formData?.supplierId || formData?.productId
                    ? {
                        ...formData,
                        supplierId: formData?.supplierId?.value,
                        productId: formData?.productId?.value,
                    }
                    : formData;

            try {
                setLoading(true);
                const res = await API.get(`${url}`, {
                    params: asyncFormData,
                });
                const { data, nb } = res?.data;

                setData(nb);
                setTableData(data);
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        };
        fetch();
    }, [url, formData]);
    useEffect(() => {
        if (!loading && Object.keys(data).length > 0) {
            setPieData(Object.entries(data).map((d) => d[1]));
        }
    }, [loading, data]);
    const reducer = (accumulator, curr) => +accumulator + +curr;
    useEffect(() => {
        pieDataSet.length > 0 && setTotalItems(pieDataSet.reduce(reducer));
    }, [pieDataSet]);
    const pourcentage = (el) => {
        return el === 0 ? "0" : ((el * 100) / totalItems).toFixed(2);
    };

    return {
        data,
        tableData,
        loading,
        setData,
        pieDataSet,
        totalItems,
        pourcentage,
    };
};

export const useDataStats = (url, formData) => {
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pieDataSet, setPieData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalUnites, setTotalUnites] = useState(0);

    const getColor = (key, name) => {
        switch (key) {
            case 0:
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gridGap: 10,
                        }}
                    >
                        <div
                            style={{
                                height: 14,
                                width: 14,
                                borderRadius: 50,
                                backgroundColor: "#F5792C",
                            }}
                        ></div>{" "}
                        <div>{name}</div>
                    </div>
                );
            case 1:
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gridGap: 10,
                        }}
                    >
                        <div
                            style={{
                                height: 14,
                                width: 14,
                                borderRadius: 50,
                                backgroundColor: "#FADA83",
                            }}
                        ></div>{" "}
                        <div>{name}</div>
                    </div>
                );
            case 2:
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gridGap: 10,
                        }}
                    >
                        <div
                            style={{
                                height: 14,
                                width: 14,
                                borderRadius: 50,
                                backgroundColor: "#EBC132",
                            }}
                        ></div>{" "}
                        <div>{name}</div>
                    </div>
                );
            case 3:
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gridGap: 10,
                        }}
                    >
                        <div
                            style={{
                                height: 14,
                                width: 14,
                                borderRadius: 50,
                                backgroundColor: "#5D4BA7",
                            }}
                        ></div>{" "}
                        <div>{name}</div>
                    </div>
                );
            case 4:
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gridGap: 10,
                        }}
                    >
                        <div
                            style={{
                                height: 14,
                                width: 14,
                                borderRadius: 50,
                                backgroundColor: "#1BBAAC",
                            }}
                        ></div>{" "}
                        <div>{name}</div>
                    </div>
                );
            default:
                break;
        }
    };
    useLayoutEffect(() => {
        const fetch = async () => {
            const asyncFormData = formData?.productId
                ? {
                    ...formData,
                    productId: formData?.productId?.value,
                }
                : formData;

            try {
                setLoading(true);
                const res = await API.get(`${url}`, {
                    params: asyncFormData,
                });
                const { data: tableData, nb: statsData } = res?.data;
                setData(statsData);
                setTableData(tableData);
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        };
        fetch();
    }, [url, formData]);
    useEffect(() => {
        if (!loading && Object.keys(data).length > 0) {
            setPieData(Object.entries(data).map((d) => d[1]));
        }
    }, [loading, data]);
    const reducer = (accumulator, curr) => accumulator + +curr.value;
    const reducerCount = (accumulator, curr) => accumulator + (curr?.count ?? 0);
    useEffect(() => {
        data.length > 0 && setTotalItems(data.reduce(reducer, 0));
        data.length > 0 && setTotalUnites(data.reduce(reducerCount, 0));
    }, [data]);

    return {
        data,
        tableData,
        loading,
        setData,
        pieDataSet,
        totalItems,
        totalUnites,
        getColor,
    };
};
