import { useAcl } from "hooks";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { SpinnerLoading } from "@sobrus-com/sobrus-design-system-v2";
// export const PrivateRoute = ({ component: Component, ...rest }) => {
//     const { isLogin } = useContext(UserContext);

//     return (
//         <Route {...rest} render={(props) => (isLogin ? <Component {...props} /> : <Redirect to="/signin" />)} />
//     );
// };

export const PrivateRoute = ({ component, canDoes }) => {
    const { canAll } = useAcl();
    const { isLogin, isFetching, isAuthenticatedUser } = useContext(UserContext);

    if (!isFetching && isAuthenticatedUser === "true") {
        if (isLogin) {
            if (canAll(canDoes) || !canDoes) {
                return component;
            } else {
                return <Navigate to="/denied_access" />;
            }
        } else {
            return <Navigate to="/signin" />;
        }
    } else if (!isAuthenticatedUser || isAuthenticatedUser === "false") {
        return <Navigate to="/signin" />;
    } else return <Loader />;
};
const Loader = () => (
    <div className="sns">
        <div className="loader">
            <SpinnerLoading loading />
        </div>
    </div>
);
