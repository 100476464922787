import React, { createContext, useState } from "react";

export const DataContextProvider = ({ children }) => {
  const [optionsData, setOptionsData] = useState({});
  const [tableData, setTableData] = useState({});
  //(tableData)
  return (
    <DataContext.Provider
      value={{ optionsData, setOptionsData, tableData, setTableData }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DataContext = createContext();
