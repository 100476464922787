import { Button } from "@sobrus-com/sobrus-design-system-v2";
import { axiosPatch } from "API/API";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./NotifCard.scss";

interface notifCardProps {
    content: string;
    context: string;
    action: string | null;
    time: string;
    setShowNotifs?: (arg: boolean) => boolean;
    isSeen: boolean;
    isClicked: boolean;
    notifId: number;
    resourceId: number;
    setNotifs: any;
}

const NotifCard: React.FC<notifCardProps> = ({
    content,
    context,
    action,
    time,
    isSeen,
    isClicked,
    notifId,
    resourceId,
    setShowNotifs,
    setNotifs,
    ...props
}) => {
    const [seen, setSeen] = useState(isSeen);
    const navigate = useNavigate();

    const handleSeen = (id: number) => {
        axiosPatch("api/notifications/mark-as-seen", {
            notificationId: id,
        }).then((res: any) => {
            if (res?.data) {
                setSeen(true);
                setNotifs((prev: any) => ({ ...prev, unseenCount: prev?.unseenCount - 1 }));
            }
        });
    };

    const handleClicked = (id: number) => {
        axiosPatch(`api/notifications/${id}/mark-as-clicked`);
    };

    return (
        <div
            className={`notif-card-container${seen ? "" : " notif-card-container--active"}`}
            onMouseEnter={() => !seen && handleSeen(notifId)}
            {...props}
        >
            <div className="notif-card-content">
                {context === "orders" ? (
                    action === "creation" ? (
                        <ReceivedOrderSVG />
                    ) : action === "update_status_48" || action === "update_status_72" ? (
                        <StockSVG />
                    ) : (
                        <CanceledOrderSVG />
                    )
                ) : context === "stock_alert" ? (
                    <StockSVG />
                ) : (
                    <StockSVG />
                )}
                <div className="notif-card-info-wrapper">
                    <div className="notif-card-info">
                        <div className="notif-card-desc">{content}</div>
                        <div className="notif-card-time">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_3798_1407)">
                                    <path
                                        d="M7.99212 6.41891e-06C6.41009 0.0014635 4.86401 0.471921 3.54931 1.35191C2.23461 2.23189 1.21032 3.4819 0.605913 4.94392C0.00150675 6.40595 -0.155878 8.01434 0.153653 9.56579C0.463184 11.1172 1.22574 12.5421 2.34491 13.6602C3.46409 14.7784 4.88965 15.5396 6.44138 15.8477C7.99312 16.1558 9.60137 15.997 11.0628 15.3912C12.5243 14.7855 13.7734 13.76 14.6521 12.4445C15.5309 11.129 15.9999 9.58249 15.9999 8.00047C16.0003 6.94911 15.7934 5.90799 15.391 4.93669C14.9886 3.96538 14.3986 3.08295 13.6549 2.33987C12.9111 1.59679 12.0281 1.00765 11.0565 0.606148C10.0848 0.20465 9.04348 -0.00132555 7.99212 6.41891e-06ZM7.99949 14.4001C6.73358 14.4001 5.4961 14.0247 4.44353 13.3214C3.39097 12.6181 2.57059 11.6185 2.08615 10.4489C1.60171 9.27938 1.47495 7.99244 1.72192 6.75086C1.96889 5.50927 2.57848 4.3688 3.47361 3.47367C4.36875 2.57854 5.50922 1.96894 6.7508 1.72198C7.99239 1.47501 9.27932 1.60176 10.4489 2.08621C11.6184 2.57065 12.6181 3.39102 13.3214 4.44359C14.0247 5.49615 14.4 6.73364 14.4 7.99955C14.4 9.69692 13.7258 11.3248 12.5257 12.5251C11.3256 13.7254 9.69686 14.3999 7.99949 14.4001Z"
                                        fill="#627282"
                                    />
                                    <path
                                        d="M7.95601 4.01405H6.70703V9.01181L11.0803 11.635L11.7048 10.6099L7.95601 8.38732V4.01405Z"
                                        fill="#627282"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3798_1407">
                                        <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {time && <p>{format(parseISO(time), "MMM dd, yyyy à HH:mm")}</p>}
                        </div>
                    </div>
                    <Button
                        size="sm"
                        color="initial"
                        onClick={() => {
                            context === "orders" && action === "update_status_48"
                                ? navigate(
                                      "/ventes/commandes-clients?orderBy=orderDate&orderStatus=en attente de confirmation"
                                  )
                                : context === "orders" && action === "update_status_72"
                                ? navigate(
                                      "/ventes/commandes-clients?orderBy=orderDate&orderStatus=en cours de traitement"
                                  )
                                : context === "orders"
                                ? navigate(`/ventes/commandes-clients/details/${resourceId}`)
                                : navigate("/achats/proposition-commande/creation");
                            if (setShowNotifs) setShowNotifs(false);
                            if (!isClicked) handleClicked(notifId);
                        }}
                    >
                        {context === "orders"
                            ? "Voir plus"
                            : context === "stock_alert"
                            ? "Créer une proposition de commande"
                            : ""}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const ReceivedOrderSVG = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="9" fill="#E3F0FB" />
        <path
            d="M11 11H13L13.4 13M15 21H25L29 13H13.4M15 21L13.4 13M15 21L12.7071 23.2929C12.0771 23.9229 12.5233 25 13.4142 25H25M25 25C23.8954 25 23 25.8954 23 27C23 28.1046 23.8954 29 25 29C26.1046 29 27 28.1046 27 27C27 25.8954 26.1046 25 25 25ZM17 27C17 28.1046 16.1046 29 15 29C13.8954 29 13 28.1046 13 27C13 25.8954 13.8954 25 15 25C16.1046 25 17 25.8954 17 27Z"
            stroke="#155077"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

const CanceledOrderSVG = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="9" fill="#FFE7E9" />
        <path
            d="M14 26L26 14M14 14L26 26"
            stroke="#C5353E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

const StockSVG = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="9" fill="#F9EBCF" />
        <path
            d="M20 17V19M20 23H20.01M13.0718 27H26.9282C28.4678 27 29.4301 25.3333 28.6603 24L21.7321 12C20.9623 10.6667 19.0378 10.6667 18.268 12L11.3398 24C10.57 25.3333 11.5322 27 13.0718 27Z"
            stroke="#D89300"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default NotifCard;
