import { axiosGet } from "API/API";
import React, { createContext, useEffect, useState } from "react";

export const NotifContextProvider = ({ children }: { isLogin: boolean; children: React.ReactNode }) => {
    const [notifs, setNotifs] = useState(null);
    const [isFetchingNotifs, setIsFetchingNotifs] = useState(true);

    useEffect(() => {
        if (isFetchingNotifs) {
            // const searchTerm = searchOrder();
            axiosGet<any>(`api/notifications?limit=15&page=1`).then((res) => {
                if (res?.data) {
                    setNotifs(res?.data);
                    setIsFetchingNotifs(false);
                }
            });
        }
    }, [isFetchingNotifs]);

    return <NotifContext.Provider value={{ notifs, setNotifs, isFetchingNotifs }}>{children}</NotifContext.Provider>;
};

export const NotifContext = createContext({});
