/* eslint-disable indent */
import { useState, useCallback, useLayoutEffect, useMemo, useContext } from "react";
import API from "API/ApiConfig";
import { isEqual } from "lodash";
import { DataContext } from "context/DataContext";
import { catchFunction } from "utils/functionHelper";
import { unstable_batchedUpdates } from "react-dom";

export const useOptions = (op, url, selectOptions, name = "") => {
  const [options, setOptions] = useState([]);
  const [, setLoading] = useState(false);
  const { optionsData, setOptionsData } = useContext(DataContext);
  const label = (d) => (selectOptions.length === 3 ? d[selectOptions[1]] + " " + d[selectOptions[2]] : d[selectOptions[1]]);
  const getOptions = useCallback(
    async (signal) => {
      unstable_batchedUpdates(async () => {
        if (optionsData[op]?.isEmty === false) {
          setOptions(optionsData[op].data);
          try {
            const { data } = await API.get(`${url}?limit=10000${op === "ClientOptions" ? "&status=actif" : ""}`, { signal });
            const opData = name
              ? data[name]?.map((d) => ({
                  value: d[selectOptions[0]],
                  label: label(d),
                  ...d,
                }))
              : data?.map((d) => ({
                  value: d[selectOptions[0]],
                  label: label(d),
                  ...d,
                }));

            if (!isEqual(opData, optionsData[op].data)) {
              setOptions(opData);
              setOptionsData((prev) => ({
                ...prev,
                [op]: { data: opData, isEmty: false },
              }));
              // // (opData);
            }
          } catch (error) {
            catchFunction(error);
          }
        } else {
          try {
            setLoading(true);
            const { data } = await API.get(`${url}?limit=10000${op === "ClientOptions" ? "&status=actif" : ""}`);

            const optionsData = name
              ? data[name]?.map((d) => ({
                  value: d[selectOptions[0]],
                  label: label(d),
                  ...d,
                }))
              : data?.map((d) => ({
                  value: d[selectOptions[0]],
                  label: label(d),
                  ...d,
                }));

            setOptions(optionsData);
            // // (optionsData, 'optionsData');
            setOptionsData((prev) => ({
              ...prev,
              [op]: { data: optionsData, isEmty: false },
            }));
            setLoading(false);
          } catch (error) {
            catchFunction(error, setLoading);
          }
        }
      });
    },
    [url]
  );

  useLayoutEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getOptions(signal);

    return () => controller.abort();
  }, [getOptions]);

  return useMemo(() => options, [options]);
};
