//*******************Achats**********************
//proposition de commande
export const orderSuggMessages = {
    CREATE: "La proposition de commande a été créée avec succès",
    CREATE_fail: "La proposition de commande n'a pas été créée",
    UPDATE: "La proposition de commande a été modifiée avec succès",
    UPDATE_fail: "La proposition de commande n'a pas été modifiée",
    APPROVE: "La proposition de commande a été approuvée avec succès",
    APPROVE_fail: "La proposition de commande n'a pas été approuvée",
    CANCEL: "La proposition de commande a été annulée avec succès",
    STATUS_fail: "Échoué !",
};
//transfert entrepot
export const transfertMessages = {
    CREATE: "Transfert d'entrepôt a été créé avec succès",
    CREATE_fail: "Transfert d'entrepôt n'a pas été créé",
    UPDATE: "Transfert d'entrepôt a été modifié avec succès",
    UPDATE_fail: "Transfert d'entrepôt n'a pas été modifié",
    APPROVE: "Transfert d'entrepôt a été approuvé avec succès",
    APPROVE_fail: "Transfert d'entrepôt n'a pas été approuvé",
    CANCEL: "Transfert d'entrepôt a été annulé avec succès",
    STATUS_fail: "Échoué !",
};
//demande de prix
export const priceRequestMessages = {
    CREATE: "La demande de prix a été créée avec succès",
    CREATE_fail: "La demande de prix n'a pas été créée",
    UPDATE: "La demande de prix a été modifiée avec succès",
    UPDATE_fail: "La demande de prix n'a pas été modifiée",
    APPROVE: "La demande de prix a été approuvée avec succès",
    APPROVE_fail: "La demande de prix n'a pas été approuvée",
    CANCEL: "La demande de prix a été annulée avec succès",
    STATUS_fail: "Échoué !",
};
//Bon de commande
export const orderMessages = {
    CREATE: "Le bon de commande a été créé avec succès",
    CREATE_fail: "Le bon de commande n'a pas été créé",
    UPDATE: "Le bon de commande a été modifié avec succès",
    UPDATE_fail: "Le bon de commande n'a pas été modifié",
    APPROVE: "Le bon de commande a été approuvé avec succès",
    APPROVE_fail: "Le bon de commande n'a pas été approuvé",
    CANCEL: "Le bon de commande a été annulé avec succès",
    STATUS_fail: "Échoué !",
};
//bon de receptions
export const recepsMessages = {
    CREATE: "Le bon de réception a été créé avec succès",
    CREATE_fail: "Le bon de réception n'a pas été créé",
    UPDATE: "Le bon de réception a été modifié avec succès",
    UPDATE_fail: "Le bon de réception n'a pas été modifié",
    APPROVE: "Le bon de réception a été approuvé avec succès",
    APPROVE_fail: "Le bon de réception n'a pas été approuvé",
    CANCEL: "Le bon de réception a été annulé avec succès",
    STATUS_fail: "Échoué !",
};
//Facture fournisseur
export const invoiceMessages = {
    CREATE: "La facture fournisseur a été créée avec succès",
    CREATE_fail: "La facture fournisseur n'a pas été créée",
    UPDATE: "La facture fournisseur a été modifiée avec succès",
    UPDATE_fail: "La facture fournisseur n'a pas été modifiée",
    APPROVE: "La facture fournisseur a été approuvée avec succès",
    APPROVE_fail: "La facture fournisseur n'a pas été approuvée",
    CANCEL: "La facture fournisseur a été annulée avec succès",
    STATUS_fail: "Échoué !",
};
//Avoir emis
export const avoirEmisMessages = {
    CREATE: "L'avoir émis a été créé avec succès",
    CREATE_fail: "L'avoir émis n'a pas été créé",
    UPDATE: "L'avoir émis a été modifié avec succès",
    UPDATE_fail: "L'avoir émis n'a pas été modifié",
    APPROVE: "L'avoir émis a été approuvé avec succès",
    APPROVE_fail: "L'avoir émis n'a pas été approuvé",
    CANCEL: "L'avoir émis a été annulé avec succès",
    STATUS_fail: "Échoué !",
};
//bon de sortie
export const bonSortieMessages = {
    CREATE: "Le bon de sortie a été créé avec succès",
    CREATE_fail: "Le bon de sortie n'a pas été créé",
    UPDATE: "Le bon de sortie a été modifié avec succès",
    UPDATE_fail: "Le bon de sortie n'a pas été modifié",
    APPROVE: "Le bon de sortie a été approuvé avec succès",
    APPROVE_fail: "Le bon de sortie n'a pas été approuvé",
    CANCEL: "Le bon de sortie a été annulé avec succès",
    STATUS_fail: "Échoué !",
};
//Avoir reçu
export const avoirRecuMessages = {
    CREATE: "L'avoir fournisseur reçu a été créé avec succès",
    CREATE_fail: "L'avoir fournisseur reçu n'a pas été créé",
    UPDATE: "L'avoir fournisseur reçu a été modifié avec succès",
    UPDATE_fail: "L'avoir fournisseur reçu n'a pas été modifié",
    APPROVE: "L'avoir fournisseur reçu a été approuvé avec succès",
    APPROVE_fail: "L'avoir fournisseur reçu n'a pas été approuvé",
    CANCEL: "L'avoir fournisseur reçu a été annulé avec succès",
    STATUS_fail: "Échoué !",
};
//*******************Parc auto**********************
export const carsMessages = {
    CREATE: "Le véhicule a été créé avec succès",
    CREATE_fail: "Le véhicule n'a pas été créé",
    UPDATE: "Le véhicule a été modifié avec succès",
    UPDATE_fail: "Le véhicule n'a pas été modifié",
    AFFECT: "Le véhicule a été affecté avec succès",
    RESTITUT: "Le véhicule a été restitué avec succès",
    STATUS_fail: "Échoué !",
};
//*******************Fournisseur**********************
export const supplierMessages = {
    SUGGEST: "Le fournisseur a été suggéré avec succès",
    SUGGEST_fail: "Le fournisseur n'a pas été suggéré",
    UPDATE: "Le fournisseur a été modifié avec succès",
    UPDATE_fail: "Le fournisseur n'a pas été modifié",
    PAYMENT_fail: "Le paiement n'a pas été enregistré",
};
//*******************Fournisseur**********************
export const productMessages = {
    SUGGEST: "Le produit a été suggéré avec succès",
    SUGGEST_fail: "Le produit n'a pas été suggéré",
    UPDATE: "Le produit a été modifié avec succès",
    UPDATE_fail: "Le produit n'a pas été modifié",
    UPDATE_fail_stock: "Le stock maximum doit être supérieure au stock minimum",
    IMPORT_FILE: "Le fichier a été importé avec succès",
    IMPORT_FILE_fail: "Le fichier n'a pas été importé",
    IMPORT_FILE_type_fail: "Le type de fichier doit être .csv",
    REVOKE_IMPORT: "Le fichier a bien été supprimé",
    REVOKE_IMPORT_fail: "Le fichier n'a pas été supprimé",
};
//*******************Contact**********************
export const contactMessages = {
    CREATE: "Le contact a été créé avec succès",
    CREATE_fail: "Le contact n'a pas été créé",
    UPDATE: "Le contact a été modifié avec succès",
    UPDATE_fail: "Le contact n'a pas été modifié",
    ARCHIVE: "Le contact a été archivé avec succès",
    ARCHIVE_fail: "Le contact n'a pas été archivé",
    RESTORE: "Le contact a été restauré avec succès",
    RESTORE_fails: "Le contact n'a pas été restauré",
};
//*******************Bons de commande**********************
export const commandeMessages = {
    VALIDATE: "La commande est validée avec succès",
    CANCEL: "La commande est annulée avec succès",
    STATUS_fail: "La commande n'a pas été enregistrée",
};
