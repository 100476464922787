import { useContext, useCallback } from "react";
import { UserContext } from "context/userContext";

export const useAcl = () => {
    const { user: currUser } = useContext(UserContext);

    const acl_methods = currUser?.role?.methods;
    const can = useCallback(
        (action) => {
            if (action !== undefined) {
                if (currUser) {
                    if (currUser?.isAdmin) return true;
                    if (action?.some((d) => d === "true")) return true;

                    return acl_methods?.some((object) => action.includes(object.action));
                }
                return false;
            }
            return false;
        },
        [acl_methods, currUser]
    );

    const canIndex = useCallback(
        (action) => {
            if (currUser?.isAdmin) return 0;
            return action.indexOf(action?.find((a) => acl_methods.some((acl) => acl.action === a)));
        },
        [acl_methods, currUser]
    );

    const canAll = useCallback(
        (action) => {
            if (action !== undefined) {
                if (currUser) {
                    if (currUser?.isAdmin) return true;

                    const verifActions = action.map((_, key) =>
                        acl_methods?.some((object) => object.action === action[key])
                    );
                    return verifActions.every((el) => el === true);
                }
                return false;
            }
            return false;
        },
        [acl_methods, currUser]
    );
    return { can, canIndex, canAll };
};
