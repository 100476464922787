/* eslint-disable no-restricted-globals */
import { useState, useCallback, useLayoutEffect, useEffect } from "react";
import API from "API/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "@sobrus-com/sobrus-design-system-v2";
import { catchFunction } from "utils/functionHelper";

export const useCrud = (url = "", name = "", dataFetching) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pages, setPages] = useState(0);
    const navigate = useNavigate();
    const FetchPost = useCallback(
        async (signal) => {
            try {
                setLoading(true);
                const res = await API.post(`${url}`, dataFetching.formData, {
                    params: dataFetching.queryState,
                    signal,
                });
                setData(res?.data[name] || res?.data);
                setPages(Math.ceil(res?.data?.total / dataFetching.queryState?.limit));
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        },
        [name, dataFetching, url]
    );
    const FetchGet = useCallback(
        async (signal) => {
            dataFetching?.formData &&
                Object.keys(dataFetching?.formData).map(
                    (key, index) => dataFetching?.formData[key] === "" && delete dataFetching?.formData[key]
                );
            try {
                setLoading(true);
                const res = await API.get(`${url}`, {
                    params: { ...dataFetching.queryState, ...dataFetching.formData },
                    signal,
                });
                setData(res?.data[name] || res?.data);
                setPages(Math.ceil(res?.data?.total / dataFetching.queryState?.limit));
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        },
        [name, dataFetching, url]
    );
    const Add = async (url, formData, redirrectUrl = "", redirrect = false, message = "") => {
        // Object.keys(formData).map((key, index) => formData[key] === "" && delete formData[key]);
        try {
            setLoading(true);
            const { data } = await API.post(`${url}`, formData);
            setLoading(false);
            if (redirrectUrl || redirrect)
                navigate(redirrect || `${redirrectUrl}/${data?.id}`, {
                    state: { detail: "success", message },
                });
        } catch (error) {
            catchFunction(error, setLoading);
        }
    };
    const AddSamePage = async (url, formData, setOpen, message, functionHelper = false) => {
        try {
            setFormLoading(true);
            const { data } = await API.post(`${url}`, formData);
            setFormLoading(false);
            setOpen && setOpen(false);
            functionHelper ? functionHelper(data) : setData((prev) => [data, ...prev]);
            if (message) {
                notify({
                    type: "success",
                    msg: message,
                    delay: 5000,
                });
            }
        } catch (error) {
            catchFunction(error, setFormLoading);
        }
    };
    const Update = async (url, formData, redirrectUrl = "", redirrect = false, message = "") => {
        try {
            // Object.keys(formData).map((key, index) => formData[key] === "" && delete formData[key]);
            setLoading(true);
            const { data } = await API.patch(`${url}`, formData);
            navigate(redirrect || `${redirrectUrl}/${data?.id}`, {
                state: { detail: "success", message },
            });
            setLoading(false);
        } catch (error) {
            catchFunction(error, setLoading);
        }
    };
    const UpdateSamePage = async (url, formData, setOpen, message, functionHelper = false) => {
        try {
            setFormLoading(true);
            const { data } = await API.patch(`${url}`, formData);
            setFormLoading(false);
            setOpen && setOpen(false);
            functionHelper
                ? functionHelper(data)
                : setData((prev) =>
                      prev.map((d) => {
                          if (d.id === data.id) {
                              return data;
                          }
                          return d;
                      })
                  );
            notify({
                type: "success",
                msg: message,
                delay: 5000,
            });
        } catch (error) {
            catchFunction(error, setFormLoading);
        }
    };
    const Delete = async (url, id, msgData, setLoading, setDeleteOpen) => {
        try {
            setLoading(true);
            await API.delete(`${url}/${id}`);
            const newData = data.filter((d) => d.id !== id);
            setData(newData);
            notify({
                type: "success",
                msg: `${msgData}`,
                delay: 5000,
            });
            setDeleteOpen && setDeleteOpen(false);
            setLoading(false);
        } catch (error) {
            catchFunction(error, setLoading);
        }
    };
    const DeleteButton = async (url, msgData, setButtonLoading, setData = () => {}) => {
        try {
            setButtonLoading(true);
            await API.delete(`${url}`);
            setData?.();
            notify({
                type: "success",
                msg: `${msgData}`,
                delay: 5000,
            });
            setButtonLoading(false);
        } catch (error) {
            catchFunction(error, setButtonLoading);
        }
    };
    return {
        data,
        setData,
        loading,
        setLoading,
        error,
        setError,
        pages,
        setPages,
        FetchGet,
        FetchPost,
        Delete,
        Update,
        Add,
        AddSamePage,
        UpdateSamePage,
        formLoading,
        DeleteButton,
    };
};

export const useGetOne = (url, propsId = null) => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const fetch = useCallback(
        async (signal) => {
            try {
                setLoading(true);
                if (propsId || id) {
                    const res = await API.get(`${url}`, { signal });
                    setData(res?.data);
                }
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        },
        [url, id, propsId]
    );

    useLayoutEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(signal);
        return () => controller.abort();
    }, [fetch]);
    return { data, loading, setLoading, setData, fetch };
};

export const usePostOne = (url, formData) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const res = await API.post(`${url}`, formData);
                setData(res?.data);
                setLoading(false);
            } catch (error) {
                catchFunction(error, setLoading);
            }
        };

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);
    return { data, loading, setData };
};
